import React, { useState } from "react";
import { Button, Input, Checkbox } from "@bigbinary/neetoui";
import authenticationApi from "apis/authentication";
import { useUserState } from "contexts/user";
import CobblerBlack from "images/CobblerBlack.png";

const Login = ({ history }) => {
  const { env_variables } = useUserState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      const {
        data: { auth_token, user },
      } = await authenticationApi.login({
        user: {
          email,
          password,
          rememberMe,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
      window.location.href = `${window.location.protocol}//${user.account_subdomain}.${env_variables.root_domain}/redirect?token=${auth_token}`;
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-grow h-screen neeto-ui-bg-white">
      <div
        className="relative w-full p-8 m-10 border border-black"
        style={{ borderRadius: 20 }}
      >
        <img
          src={CobblerBlack}
          alt="Cobbler Logo"
          className="absolute w-auto h-7 left-8 bottom-8"
        />
        <a
          href="https://cobbler.app/"
          className="absolute text-sm font-medium leading-5 text-black underline left-8 top-8"
        >
          Back to Cobbler
        </a>
        <div className="flex flex-col items-center justify-center flex-grow w-5/12 h-full py-20 mx-auto">
          <h2 className="mb-10 text-3xl font-semibold text-center text-black">
            Sign in to your account
          </h2>

          <form
            className="flex flex-col items-center w-full signin-form__wrapper"
            onSubmit={handleSubmit}
          >
            <Input
              required
              type="email"
              value={email}
              label="Email"
              placeholder="oliver@example.com"
              id="user_email"
              className="w-full mb-8"
              onChange={e => setEmail(e.target.value)}
            />

            <Input
              required
              type="password"
              name="password"
              id="user_password"
              label="Password"
              placeholder="******"
              value={password}
              className="w-full"
              onChange={e => setPassword(e.target.value)}
            />

            <div className="flex items-center justify-between w-full mt-6">
              <div className="flex items-center">
                <Checkbox
                  className="pt-0.5 signin-checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <div className="ml-2">
                  <p>Remember me</p>
                </div>
              </div>
              <div>
                <Button
                  style="link"
                  label="Forgot your password?"
                  onClick={() => {
                    history.push("/my/password/new");
                  }}
                />
              </div>
            </div>
            <div>
              <Button
                type="submit"
                className="px-12 py-4 text-2xl font-medium rounded-full mt-14 signin-btn"
                loading={loading}
                label="Sign In"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
