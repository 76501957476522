import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { updateJob } from "apis/jobs/jobs";
import { showToastrError } from "common";
import { Typography } from "@bigbinary/neetoui";
import SingleImageUploader from "components/Common/SingleImageUploader";

const JobThumbnail = ({ jobDetail, loadJobDetailResponse }) => {
  const [initial, setInitial] = useState(true);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: jobDetail,
    onSubmit: () => updateJobEntry(),
  });

  useEffect(() => {
    if (!initial) {
      updateJobEntry();
    }
  }, [formik.values]);

  const updateJobEntry = async () => {
    try {
      await updateJob(jobDetail.id, {
        job: {
          thumbnail_attachment_attributes: {
            ...formik.values.attachments_attributes,
            attachable_kind: "thumbnail",
          },
        },
      });
      loadJobDetailResponse();
      setInitial(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <Typography style="h4" weight="semibold">
        Job Thumbnail
      </Typography>
      <SingleImageUploader
        name="Only JPEG, JPG, PNG files are supported"
        type="thumbnail"
        setDetailInitial={setInitial}
        formValue={formik.values}
        setFormValue={formik.setFieldValue}
      />
    </div>
  );
};

export default JobThumbnail;
