import React, { useState, useEffect } from "react";
import {
  Button,
  Pane,
  Spinner,
  Typography,
  Input,
  Select,
  Textarea,
  Label,
  Switch,
} from "@bigbinary/neetoui";
import { updateJob } from "apis/jobs/jobs";
import { getSpecialConditions } from "apis/settings/special_conditions";
import { getTaxTypes } from "apis/settings/tax_types";
import { useFormik } from "formik";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import { getFinalInvoice, getCommencementInvoice } from "./helper";

import { INITIAL_VALUE } from "./constants";

const EstimateSettingPane = ({
  estimateSettingsOpen,
  setEstimateSettingsOpen,
  jobDetail,
  loadJobDetailResponse,
  invoiceList,
}) => {
  const [specialConditionList, setSpecialConditionList] = useState([]);
  const [specialConditionListLoad, setSpecialConditionListLoad] = useState(
    true
  );
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxTypeLoad, setTaxTypeLoad] = useState(true);

  useEffect(() => {
    if (jobDetail.id && estimateSettingsOpen) {
      loadSpecialConditionListResponse();
      loadTaxTypes();
    }
  }, [estimateSettingsOpen]);

  const loadSpecialConditionListResponse = async () => {
    try {
      const response = await getSpecialConditions();
      setSpecialConditionList(
        dropDownListGenerator(response.data.specialConditions || [])
      );
      setSpecialConditionListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadTaxTypes = async () => {
    try {
      const { data } = await getTaxTypes();
      setTaxTypes(dropDownListGenerator(data.taxTypes));
      setTaxTypeLoad(false);
    } catch (error) {
      showToastrError(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: jobDetail?.id ? jobDetail : INITIAL_VALUE,
    onSubmit: values => updateJobEntry(values),
  });

  const updateJobEntry = async formValue => {
    try {
      await updateJob(jobDetail.id, { job: { ...formValue } });
      setEstimateSettingsOpen(false);
      loadJobDetailResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <Pane
      isOpen={estimateSettingsOpen}
      onClose={() => setEstimateSettingsOpen(false)}
    >
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          Estimate Settings
        </Typography>
      </Pane.Header>
      <Pane.Body>
        {specialConditionListLoad || taxTypeLoad ? (
          <div
            className="flex items-center justify-center w-full"
            style={{ height: "calc(100vh - 180px)" }}
          >
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col w-full pt-3 space-y-6">
            <div className="flex flex-col space-y-2">
              <div className="flex justify-between">
                <div className="flex items-center space-x-2">
                  <Label>Add</Label>
                  <Select
                    isClearable={true}
                    options={taxTypes}
                    isDisabled={
                      getFinalInvoice(invoiceList) ||
                      getCommencementInvoice(invoiceList)
                    }
                    value={taxTypes.find(
                      type =>
                        formik.values.taxTypeId &&
                        type.value === formik.values.taxTypeId
                    )}
                    onChange={option => {
                      if (option) {
                        formik.setFieldValue("taxTypeId", option.value);
                        formik.setFieldValue("taxTypeName", option.label);
                      } else {
                        formik.setFieldValue("taxTypeId", null);
                      }
                    }}
                  />
                  <Label>to Estimate</Label>
                  <Input
                    type="number"
                    className="flex items-start"
                    name="taxPercentage"
                    disabled={
                      getFinalInvoice(invoiceList) ||
                      getCommencementInvoice(invoiceList)
                    }
                    onChange={formik.handleChange}
                    value={formik.values.taxPercentage}
                  />
                </div>
              </div>

              {formik.values.taxTypeId && (
                <Typography style="body3" className="neeto-ui-text-gray-600">
                  {formik.values.taxTypeName || taxTypes[0].label} is an
                  indirect tax used in {jobDetail.location} on the supply of
                  goods and services.
                </Typography>
              )}
            </div>
            <Select
              label="Special Conditions"
              isMulti
              options={specialConditionList}
              value={specialConditionList.filter(condition =>
                formik.values.specialConditionIds?.includes(condition.value)
              )}
              onChange={option => {
                formik.setFieldValue(
                  "specialConditionIds",
                  option.map(op => op.value)
                );
              }}
            />

            <Input
              label="Commencement Fee"
              type="number"
              disabled={
                getFinalInvoice(invoiceList) ||
                getCommencementInvoice(invoiceList)
              }
              name="commencementFee"
              onChange={formik.handleChange}
              value={formik.values.commencementFee}
            />

            <Textarea
              label="Notes"
              name="note"
              rows={5}
              onChange={formik.handleChange}
              value={formik.values.note}
            />

            <Switch
              checked={formik.values.enableInCounter}
              onChange={() => {
                formik.setFieldValue(
                  "enableInCounter",
                  !formik.values.enableInCounter
                );
              }}
              label="Enable in Counter"
            />
          </div>
        )}
      </Pane.Body>
      <Pane.Footer className="space-x-2">
        <Button label="Save Changes" onClick={() => formik.handleSubmit()} />
        <Button
          style="text"
          label="Cancel"
          onClick={() => setEstimateSettingsOpen(false)}
        />
      </Pane.Footer>
    </Pane>
  );
};

export default EstimateSettingPane;
