import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { buildUrl } from "../utils";
import routes from "../routes";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { OTHERS_LIST } from "../constants";

import OrganisationTypes from "./OrganisationTypes";
import JobTypes from "./JobTypes";
import Currencies from "./Currencies";
import AgencyGroup from "./AgencyGroups";

import SettingsTile from "../SettingsTile";

const Others = ({ title, tab }) => {
  const { subTab } = useParams();
  const breadcrumbs = [
    { text: title, link: buildUrl(routes.settings.show, { tab }) },
  ];

  return (
    <>
      {subTab ? (
        <Switch>
          <Route
            path={routes.settings.organisationTypes}
            render={() => <OrganisationTypes breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.currencies}
            render={() => <Currencies breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.jobTypes}
            render={() => <JobTypes breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.agencyGroups}
            render={() => <AgencyGroup breadcrumbs={breadcrumbs} />}
          />
          <Redirect to={buildUrl(routes.settings.show, { tab })} />
        </Switch>
      ) : (
        <Container>
          <Header title={title} />

          <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 2xl:grid-cols-4">
            {OTHERS_LIST.map(item => (
              <SettingsTile
                {...item}
                key={item.value}
                path={buildUrl(item.path, { tab })}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  );
};

export default Others;
