import React, { useState, useEffect } from "react";
import { Input, Select, Label } from "@bigbinary/neetoui";
import SingleImageUploader from "components/Common/SingleImageUploader";
import { TAX_STATUS_LIST } from "../constants";

const InvoiceDetails = ({ formik }) => {
  const [taxStatus, setTaxStatus] = useState("");

  useEffect(() => {
    formik.setFieldValue("taxStatus", taxStatus);
  }, [taxStatus]);

  return (
    <div
      className="px-6 pt-6 pb-12 space-y-6 overflow-y-auto"
      style={{ height: "calc(100vh - 208px)" }}
    >
      <Input
        label="Tax ID"
        name="abn"
        onChange={formik.handleChange}
        value={formik.values.abn}
        error={
          Boolean(formik.touched.abn && formik.errors.abn) && formik.errors.abn
        }
        {...formik.getFieldProps("abn")}
      />

      <Select
        label="Tax Status"
        name="taxStatus"
        id="taxStatus"
        placeholder="Select an Option"
        value={TAX_STATUS_LIST.find(
          tax => tax.value === formik.values.taxStatus
        )}
        onBlur={() => formik.setFieldTouched("taxStatus", true)}
        error={
          Boolean(formik.touched.taxStatus && formik.errors.taxStatus) &&
          formik.errors.taxStatus
        }
        isClearable={true}
        touched={formik.touched.taxStatus}
        options={TAX_STATUS_LIST}
        onChange={opt => setTaxStatus(opt.value)}
      />
      <Input
        label="Name of Account"
        name="accountName"
        onChange={formik.handleChange}
        value={formik.values.accountName}
        error={
          Boolean(formik.touched.accountName && formik.errors.accountName) &&
          formik.errors.accountName
        }
        {...formik.getFieldProps("accountName")}
      />

      <Input
        label="Account Number"
        name="accountNumber"
        onChange={formik.handleChange}
        value={formik.values.accountNumber}
        error={
          Boolean(
            formik.touched.accountNumber && formik.errors.accountNumber
          ) && formik.errors.accountNumber
        }
        {...formik.getFieldProps("accountNumber")}
      />
      <div className="flex flex-col space-y-2">
        <Label>HeadShot</Label>
        <SingleImageUploader
          name="Hero"
          type="hero"
          formValue={formik.values}
          setFormValue={formik.setFieldValue}
        />
      </div>

      <div className="flex flex-col space-y-2">
        <Label>Cover</Label>
        <SingleImageUploader
          name="Cover"
          type="cover"
          formValue={formik.values}
          setFormValue={formik.setFieldValue}
        />
      </div>

      <Input
        label="Agency URL"
        name="artistUrl"
        onChange={formik.handleChange}
        value={formik.values.artistUrl}
        prefix="https://"
        error={
          Boolean(formik.touched.artistUrl && formik.errors.artistUrl) &&
          formik.errors.artistUrl
        }
        {...formik.getFieldProps("artistUrl")}
      />

      <Input
        label="Instagram"
        name="instagramUserName"
        onChange={formik.handleChange}
        value={formik.values.instagramUserName}
        prefix="@"
        error={
          Boolean(
            formik.touched.instagramUserName && formik.errors.instagramUserName
          ) && formik.errors.instagramUserName
        }
        {...formik.getFieldProps("instagramUserName")}
      />
    </div>
  );
};

export default InvoiceDetails;
