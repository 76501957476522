import { User, Info } from "@bigbinary/neeto-icons";
import { artistModuleName } from "common/helper";

export const TAB_CONSTANTS = () => {
  const artistModule = artistModuleName();

  return {
    ArtistDetails: {
      label: `${artistModule} Details`,
      icon: User,
    },
    InvoiceDetails: {
      label: "Invoice Details",
      icon: Info,
    },
  };
};
