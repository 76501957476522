import React from "react";
import { Down, Up } from "@bigbinary/neeto-icons";
import { Button, Typography } from "@bigbinary/neetoui";
import EstimateAccordian from "components/Common/EstimateAccordian";
import { sumAmount, numberWithCommas } from "common/helper";
import LineItem from "./LineItem";
import EstimateForm from "./Form";

const EstimateAccordion = ({
  estimateListItems,
  onAccordionClick,
  jobDetail,
  deleteItemClickFunction,
  artistList,
  saveEstimateRecord,
  openedItem,
  rciList,
  isDisable,
  setOpenedParent,
  openedParent,
  revisionLineItemCatgoryList,
}) => {
  return (
    <div className="w-full space-y-2">
      {revisionLineItemCatgoryList.map(parent => {
        let children = estimateListItems.filter(
          item => item.lineItemCategory?.id === parent.id
        );

        const totalOfParent = sumAmount(children, "amount");
        return (
          <EstimateAccordian.Item
            key={parent.id}
            draggable={false}
            className="border border-gray-300 bg-gray-100"
            isOpen={parent.id === openedParent}
            CustomTitle={({ isOpen }) => {
              return (
                <div className="grid w-full grid-cols-12 gap-4 ml-8">
                  <div className="flex flex-col items-start justify-center col-span-4 space-y-2">
                    <Typography style="h5" weight="semibold">
                      {parent.lineItemCategoryName}
                    </Typography>
                  </div>
                  <div className="flex self-center justify-end col-span-6 whitespace-no-wrap">
                    <Typography style="h5" weight="semibold">
                      {!isOpen && (
                        <div className="flex items-center py-2 font-semibold">
                          {numberWithCommas(Number(totalOfParent).toFixed(2))}{" "}
                          {jobDetail.currency}
                        </div>
                      )}
                    </Typography>
                  </div>
                  <div className="flex items-end self-end justify-end col-span-2">
                    <Button
                      style="text"
                      icon={isOpen ? Up : Down}
                      iconPosition="right"
                      onClick={() =>
                        setOpenedParent(() => {
                          if (openedParent == parent.id) {
                            setOpenedParent();
                          } else {
                            setOpenedParent(parent.id);
                          }
                        })
                      }
                    />
                  </div>
                </div>
              );
            }}
          >
            <div className="w-full mb-2 space-y-2">
              {estimateListItems
                .filter(item => item.lineItemCategory?.id === parent.id)
                .map((revisionItem, index) => {
                  return (
                    <LineItem
                      key={index}
                      deleteItemClickFunction={deleteItemClickFunction}
                      isOpen={openedItem === revisionItem.id}
                      rciAttached={rciList
                        .map(rci => rci.rciItems)
                        .flat()
                        .find(
                          item =>
                            item.estimateRevisionItemId === revisionItem.id
                        )}
                      revisionItem={revisionItem}
                      jobDetail={jobDetail}
                      index={index}
                      isDisable={false}
                      greyColoured={true}
                      onAccordionClick={onAccordionClick}
                      extraPadding={true}
                    >
                      <EstimateForm
                        itemObject={revisionItem}
                        index={index}
                        artistList={artistList}
                        saveEstimateRecord={saveEstimateRecord}
                        itemArray={estimateListItems}
                        jobDetail={jobDetail}
                        rciAttached={rciList
                          .map(rci => rci.rciItems)
                          .flat()
                          .find(
                            item =>
                              item.estimateRevisionItemId === revisionItem.id
                          )}
                      />
                    </LineItem>
                  );
                })}
            </div>
          </EstimateAccordian.Item>
        );
      })}
      {estimateListItems
        .filter(item => item.lineItemCategory?.id === null)
        .map((revisionItem, index) => (
          <LineItem
            key={index}
            deleteItemClickFunction={deleteItemClickFunction}
            isOpen={openedItem === revisionItem.id}
            revisionItem={revisionItem}
            jobDetail={jobDetail}
            index={index}
            isDisable={isDisable}
            greyColoured={revisionItem.kind == "estimate" || isDisable}
            onAccordionClick={onAccordionClick}
            rciAttached={rciList
              .map(rci => rci.rciItems)
              .flat()
              .find(item => item.estimateRevisionItemId === revisionItem.id)}
          >
            <EstimateForm
              itemObject={revisionItem}
              index={index}
              artistList={artistList}
              saveEstimateRecord={saveEstimateRecord}
              itemArray={estimateListItems}
              jobDetail={jobDetail}
              rciAttached={rciList
                .map(rci => rci.rciItems)
                .flat()
                .find(item => item.estimateRevisionItemId === revisionItem.id)}
            />
          </LineItem>
        ))}
    </div>
  );
};

export default EstimateAccordion;
