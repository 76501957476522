import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  Message,
  Right,
  Filter,
  Hide,
  Download,
  Tag as TagIcon,
  RemoveCircle,
} from "@bigbinary/neeto-icons";
import {
  Spinner,
  Table,
  Button,
  Typography,
  Dropdown,
  Tag,
  Alert,
} from "@bigbinary/neetoui";
import { Container, Header, SubHeader } from "@bigbinary/neetoui/layouts";
import { toast } from "react-toastify";
import * as dayjs from "dayjs";
import * as R from "ramda";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import { useUserState } from "contexts/user";
import { DEFAULT_PAGE_SIZE, getRandomNotFoundImage } from "common/helper";
import { getStaffs } from "apis/artists/staffs";
import { getContacts, exportContacts } from "apis/crms/contacts";
import { updateContact } from "apis/contacts/contacts";
import { updateBulkContacts } from "apis/crms/contacts";
import { getActiveCrmActionTags } from "apis/settings/crm_action_tags";
import { getOpenedActionTags } from "apis/crms/opened_action_tags";
import { getFilterSpecifications } from "apis/crms/filter_specifications";
import { updateContactActivityLog } from "apis/contacts/contact_activity_logs";
import { destroyContactActivityLogs } from "apis/crms/contact_activity_logs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ActivityModal from "./ActivityModal";
import NoteModal from "./NoteModal";
import BulkActivityLogCreateModal from "./BulkActivityLogCreateModal";
import BulkStaffUpdateModal from "./BulkStaffUpdateModal";
import FilterPane from "./FilterPane";
import EmptyState from "components/Common/EmptyState";
import ExportModal from "./ExportModal";
import TagRemovalModal from "./TagRemovalModal";
import TableWrapper from "components/Common/TableWrapper";

const Crm = () => {
  const user = useUserState();
  const [contactList, setContactList] = useState([]);
  const [contactListLoad, setContactListLoad] = useState(true);
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffOptionLoad, setStaffOptionLoad] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [contactDetail, setContactDetail] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [sortProps, setSortProps] = useState();
  const [activityModalState, setActivityModalState] = useState(false);
  const [crmActionTagList, setCrmActionTagList] = useState([]);
  const [crmActionTagListLoad, setCrmActionTagListLoad] = useState([]);
  const [activityLog, setActivityLog] = useState();
  const [closeTagActionState, setCloseTagActionState] = useState(false);
  const [noteModalState, setNoteModalState] = useState(false);
  const [copyEmail, setCopyEmail] = useState(false);
  const [filterAttr, setFilterAttr] = useState({});
  const [filterPaneState, setFilterPaneState] = useState(false);
  const [filterSpecificationList, setFilterSpecificationList] = useState([]);
  const [
    filterSpecificationListLoad,
    setFilterSpecificationListLoad,
  ] = useState(true);
  const [exportConfirmAlertState, setExportConfirmAlertState] = useState(false);
  const [exportLoad, setExportLoad] = useState(false);
  const [openedActionTags, setOpenedActionTags] = useState([]);
  const [openedActionTagsLoad, setOpenedActionTagsLoad] = useState(true);
  const [bulkTagIds, setBulkTagIds] = useState([]);
  const [addActionTagModalState, setAddActionTagModalState] = useState(false);
  const [addStaffModalState, setAddStaffModalState] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [initial, setInitial] = useState(true);
  const [hideAlertState, setHideAlertState] = useState(false);
  const { Menu, MenuItem, Divider } = Dropdown;
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState();
  const [removeActionTagModalState, setRemoveActionTagModalState] = useState(false);
  const [removeActionTagLoad, setRemoveActionTagLoad] = useState(false);

  useEffect(async () => {
    await loadContactListResponse();
    await loadFilterSpecificationListResponse();
    await loadStaffListResponse();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadContactListResponse();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (activityModalState) {
      loadCrmActionTagListResponse();
    }
  }, [activityModalState]);

  useEffect(() => {
    if (sortProps?.column) {
      loadContactListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (Object.keys(filterAttr)?.length > 0) loadContactListResponse();
  }, [filterAttr]);

  useEffect(() => {
    if (filterSpecificationList?.length > 0) {
      setFilterAttr(
        filterSpecificationList.find(
          spec => spec.default === true && spec.userId === user.user.id
        ) || {}
      );
    }
  }, [filterSpecificationList]);

  useEffect(() => {
    if (copyEmail) {
      toast.info("Copied to Clipboard!", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setCopyEmail(false);
    }
  }, [copyEmail]);

  useEffect(() => {
    if (!initial) {
      loadContactListResponse();
    }
  }, [pageIndex]);

  const onUpdate = async (userId, contact) => {
    try {
      let payload = {
        contact: {
          organisation_contacts_attributes: [
            { userId: userId, id: contact.organisationContactId },
          ],
        },
      };
      await updateContact(contact.id, payload);
      loadContactListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const prepareFilterParams = query => {
    const {
      actionTagIds,
      actionTagStatuses,
      contactPositions,
      countries,
      states,
      jobTypeIds,
      organisationIds,
      organisationTypeIds,
      staffIds,
      daysSinceLastActivity,
      inactiveContactSearch,
      notAssignedContactSearch,
      notEnabledContactsSearch,
    } = query;

    let filterQuery = "";
    if (actionTagIds?.length > 0)
      filterQuery += `q[contact_activity_logs_crm_action_tag_id_in]=${actionTagIds}`;
    if (actionTagStatuses?.length > 0)
      filterQuery += `&q[contact_activity_logs_status_in]=${actionTagStatuses}`;
    if (contactPositions?.length > 0)
      filterQuery += `&q[active_organisation_contact_title_i_cont_any]=${contactPositions}`;
    if (countries?.length > 0)
      filterQuery += `&q[active_organisation_addresses_country_in]=${countries}`;
    if (states?.length > 0)
      filterQuery += `&q[active_organisation_addresses_state_in]=${states}`;
    if (jobTypeIds?.length > 0)
      filterQuery += `&q[jobs_job_type_id_in]=${jobTypeIds}`;
    if (organisationIds?.length > 0)
      filterQuery += `&q[active_organisation_id_in]=${organisationIds}`;
    if (organisationTypeIds?.length > 0)
      filterQuery += `&q[active_organisation_organisation_type_id_in]=${organisationTypeIds}`;
    if (staffIds?.length > 0)
      filterQuery += `&q[organisation_contacts_user_id_in]=${staffIds}`;
    if (daysSinceLastActivity)
      filterQuery += `&q[last_accessed_gteq]=${daysSinceLastActivity}`;
    if (inactiveContactSearch)
      filterQuery += `&q[contact_activity_log_ids_null]=true`;
    if (notAssignedContactSearch)
      filterQuery += `&q[active_organisation_contact_user_id_null]=true`;
    if (notEnabledContactsSearch)
      filterQuery += `&q[not_enabled_contacts_search]=true`;

    return filterQuery;
  };

  const loadStaffListResponse = async () => {
    try {
      const response = await getStaffs();
      setStaffOptions(response.data.staffs);
      setStaffOptionLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadContactListResponse = async (filterParams = filterAttr) => {
    try {
      const response = await getContacts(
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE,
        prepareFilterParams(filterParams),
        searchParams
      );

      setContactList(response.data.contacts);
      setTotalRecords(response.data.totalRecords);
      setContactListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const exportContactList = async () => {
    try {
      setExportLoad(true);
      const { data } = await exportContacts(
        prepareFilterParams(filterAttr),
        bulkTagIds
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `contacts-crm-${dayjs().format("DD-MM-YYYY")}.csv`;
      link.click();
      setExportLoad(false);
      setExportConfirmAlertState(false);
      loadContactListResponse();
      setBulkTagIds([]);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadCrmActionTagListResponse = async () => {
    try {
      const crmActionTagListResponse = await getActiveCrmActionTags();
      setCrmActionTagList(crmActionTagListResponse.data.crmActionTags || []);
      setCrmActionTagListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadFilterSpecificationListResponse = async () => {
    try {
      const filterSpecificationListResponse = await getFilterSpecifications();
      setFilterSpecificationList(
        filterSpecificationListResponse.data.filterSpecifications || []
      );
      setFilterSpecificationListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const closeActionTag = async () => {
    try {
      await updateContactActivityLog(contactDetail.id, activityLog.id, {
        contact_activity_log: { status: "completed", closureDate: dayjs().format("YYYY-MM-DD") },
      });
      setCloseTagActionState(false);
      loadContactListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onHide = async () => {
    try {
      let payload = {
        contact: { contactIds: selectedRowIds },
      };
      await updateBulkContacts(payload);
      setHideAlertState(false);
      loadContactListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadOpenedActionTags = async () => {
    try {
      setOpenedActionTagsLoad(true);
      const response = await getOpenedActionTags(
        prepareFilterParams(filterAttr)
      );
      setOpenedActionTags(response.data.openedActionTags);
      setOpenedActionTagsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const destroyContactActivityLogsResponse = async () => {
    try {
      setRemoveActionTagLoad(true);
      await destroyContactActivityLogs(prepareFilterParams(filterAttr), bulkTagIds)
      setRemoveActionTagLoad(false);
      setRemoveActionTagModalState(false);
      setBulkTagIds([]);
      loadContactListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setRemoveActionTagLoad(false);
    }
  }

  const COLUMN_DATA = [
    {
      title: "Organisation",
      dataIndex: "organisationName",
      key: "organisationName",
      field: "active_organisation_name",
      width: 180,
      // sorter: true,
      render: (_, rowData) => (
        <Button
          style="link"
          to={`/organisations/${rowData.organisationId}`}
          label={rowData.organisationName}
        />
      ),
      fixed: "left",
    },
    {
      title: "Organisation Type",
      dataIndex: "organisationType",
      key: "organisationType",
      field: "active_organisation_organisation_type_name",
      width: 175,
      // sorter: true,
      render: (_, rowData) => (
        <>{rowData.organisationType ? rowData.organisationType : "N/A"}</>
      ),
    },
    {
      title: "Contact",
      dataIndex: "fullName",
      key: "fullName",
      width: 160,
      // sorter: true,
      field: "full_name",
      render: (_, rowData) => (
        <div className="flex items-center space-x-2">
          <Button
            style="link"
            to={`/people/${rowData.id}`}
            label={rowData.fullName}
          />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "copyEmail",
      key: "copyEmail",
      width: 10,
      render: (_, rowData) => (
        <div onClick={e => e.stopPropagation()}>
          <CopyToClipboard
            text={rowData.email}
            onCopy={() => setCopyEmail(true)}
          >
            <Button
              style="text"
              className="block"
              icon={Message}
              tooltipProps={{
                content: "Click to copy email",
                position: "top",
              }}
            />
          </CopyToClipboard>
        </div>
      ),
    },
    {
      title: "Staff",
      dataIndex: "staffName",
      key: "staffName",
      width: 175,
      // sorter: true,
      field: "active_organisation_contact_user_first_name",
      render: (_, rowData) => (
        <Dropdown
          position="bottom-end"
          onClick={e => e.stopPropagation()}
          label={
            rowData.userFullName ? (
              <Tag label={rowData.userFullName} />
            ) : (
              "Select Staff"
            )
          }
          buttonStyle="link"
          strategy="fixed"
          closeOnSelect
        >
          <Menu>
            {staffOptions.map(staff => {
              return (
                <MenuItem.Button
                  key={staff.id}
                  onClick={() => {
                    onUpdate(staff.id, rowData);
                  }}
                >
                  {staff.fullName}
                </MenuItem.Button>
              );
            })}
            <Divider />
            <MenuItem.Button
              style="danger"
              onClick={() => onUpdate(null, rowData)}
            >
              Remove Staff
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
    {
      title: "Position",
      dataIndex: "title",
      key: "title",
      // sorter: true,
      field: "active_organisation_contact_title",
      width: 90,
      render: (_, rowData) => <>{rowData.title ? rowData.title : "N/A"}</>,
    },
    {
      title: "Country",
      dataIndex: "country",
      // sorter: true,
      field: "active_organisation_addresses_country",
      key: "country",
      width: 90,
      render: (_, rowData) => <>{rowData.country ? rowData.country : "N/A"}</>,
    },
    {
      title: "State",
      dataIndex: "state",
      // sorter: true,
      field: "active_organisation_addresses_state",
      key: "state",
      width: 90,
      render: (_, rowData) => <>{rowData.state ? rowData.state : "N/A"}</>,
    },
  ];

  const actionBlockComponent = () => {
    return (
      <div className="flex items-center space-x-3">
        {selectedRowIds.length > 0 && (
          <div className="flex space-x-3">
            <Button
              style="secondary"
              label="Add Action Tag"
              onClick={() => setAddActionTagModalState(true)}
            />
            <Button
              style="secondary"
              label="Add Staff"
              onClick={() => setAddStaffModalState(true)}
            />
          </div>
        )}
        <Button
          icon={RemoveCircle}
          iconPosition="left"
          style="secondary"
          label="Remove Action Tag"
          onClick={() => {
            loadOpenedActionTags();
            setRemoveActionTagModalState(true);
          }}
        />

        <Button
          icon={Download}
          iconPosition="left"
          label="Export to CSV"
          onClick={() => {
            loadOpenedActionTags();
            setExportConfirmAlertState(true);
          }}
        />
      </div>
    );
  };

  if (
    contactListLoad ||
    filterSpecificationListLoad ||
    staffOptionLoad ||
    exportLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="CRM"
        actionBlock={actionBlockComponent()}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(""),
        }}
      />
      <SubHeader
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords} Contacts
          </Typography>
        }
        rightActionBlock={
          <div className="flex items-center space-x-3">
            {selectedRowIds.length > 0 && (
              <Button
                label="Hide"
                icon={Hide}
                iconPosition="left"
                style="secondary"
                onClick={() => setHideAlertState(true)}
              />
            )}
            <Dropdown
              label={filterAttr.name || "Select Filter"}
              buttonStyle="secondary"
              position="bottom-end"
              closeOnSelect
            >
              {filterSpecificationList ? (
                <Menu>
                  {filterSpecificationList.map(specification => {
                    return (
                      <MenuItem.Button
                        key={specification.id}
                        onClick={() => {
                          setContactListLoad(true);
                          setFilterAttr(specification);
                        }}
                      >
                        {specification.name}
                      </MenuItem.Button>
                    );
                  })}
                  <Divider />
                  <MenuItem.Button
                    style="danger"
                    onClick={async () => {
                      await setFilterAttr({});
                      loadContactListResponse(true, 1, {});
                    }}
                  >
                    Remove Filter
                  </MenuItem.Button>
                </Menu>
              ) : (
                <Menu>
                  <MenuItem>
                    <div className="flex items-center justify-center py-1">
                      <Typography style="body2">
                        No Filters Available
                      </Typography>
                    </div>
                  </MenuItem>
                </Menu>
              )}
            </Dropdown>
            <Button
              label="Filter"
              iconPosition="left"
              icon={Filter}
              style="secondary"
              onClick={() => setFilterPaneState(true)}
            />
          </div>
        }
      />

      <TableWrapper hasPagination={totalRecords > pageSize} className="px-6">
        {!R.isEmpty(contactList) ? (
          <Table
            loading={contactListLoad}
            fixedHeight
            totalCount={totalRecords}
            rowSelection
            columnData={COLUMN_DATA}
            rowData={contactList}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            selectedRowKeys={selectedRowIds}
            onRowSelect={selectedRowKeys => setSelectedRowIds(selectedRowKeys)}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) => {
              setSortProps(sorter)
            }}
            onRow={({ id }) =>
              expandedRowIds.includes(id) && {
                className: "jw-crm-expanded-row",
              }
            }
            expandable={{
              // eslint-disable-next-line react/display-name
              expandRowByClick: true,
              fixed: true,
              expandedRowKeys: expandedRowIds,
              defaultExpandedRowKeys: expandedRowIds,
              onExpand: (expanded, record) => {
                setExpandedRowIds(
                  expanded
                    ? [...expandedRowIds, record.id]
                    : expandedRowIds.filter(id => id !== record.id)
                );
              },
              expandedRowRender: record => {
                let lastActivity = record.contactActivityLogs.sort((a, b) =>
                  b.latestDate.localeCompare(a.latestDate)
                )[0];
                let lastOpenActivity = record.contactActivityLogs?.filter(
                  log => log.status === "open"
                )[0];
                return (
                  <div className="grid w-full grid-cols-3 gap-12 py-3 pl-32">
                    <div className="flex flex-col items-start justify-start w-full">
                      <Typography
                        style="h5"
                        component="h5"
                        weight="semibold"
                        className="mb-1"
                      >
                        Last Activity
                      </Typography>
                      <Typography style="body2">
                        {lastActivity ? (
                          <>
                            {dayjs(lastActivity.latestDate).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            -{" "}
                            <Tag
                              style="secondary"
                              type="solid"
                              label={lastActivity.actionTagName || "None"}
                            />
                          </>
                        ) : (
                          <>None</>
                        )}
                      </Typography>
                      <Button
                        onClick={() => {
                          setContactDetail(record);
                          setActivityModalState(true);
                        }}
                        label="Add Activity"
                        style="link"
                        className="mt-3"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <Typography
                        style="h5"
                        component="h5"
                        weight="semibold"
                        className="mb-1"
                      >
                        Open Action Tag
                      </Typography>
                      <Typography style="body2">
                        {lastOpenActivity ? (
                          <>
                            {dayjs(lastOpenActivity.latestDate).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            -{" "}
                            <Tag
                              style="secondary"
                              type="solid"
                              label={lastOpenActivity.actionTagName || "None"}
                            />
                          </>
                        ) : (
                          <>None</>
                        )}
                      </Typography>
                      {lastOpenActivity && (
                        <Button
                          style="link"
                          label="Mark as Closed"
                          className="mt-3"
                          onClick={async () => {
                            await setContactDetail(record);
                            await setActivityLog(lastOpenActivity);
                            await setCloseTagActionState(true);
                          }}
                        />
                      )}
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <Typography
                        style="h5"
                        component="h5"
                        weight="semibold"
                        className="mb-1"
                      >
                        Note/Source
                      </Typography>
                      <Typography style="body2">
                        {record.notes[0] ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: record.notes[0].note
                                ?.split("\n")
                                .join("<br>"),
                            }}
                          ></span>
                        ) : (
                          <span>None</span>
                        )}
                      </Typography>
                      <Button
                        onClick={async () => {
                          await setContactDetail(record);
                          await setNoteModalState(true);
                        }}
                        label="Add Note"
                        style="link"
                        className="mt-3"
                      />
                    </div>
                  </div>
                );
              },
              expandIcon: ({ expanded, onExpand, record }) => {
                let lastOpenActivity = record.contactActivityLogs?.filter(
                  log => log.status === "open"
                )[0];

                return (
                  <>
                    {lastOpenActivity ? (
                      <div className="flex items-center justify-start space-x-1">
                        <TagIcon size={16} color="blue" />
                        <Right
                          size={18}
                          onClick={e => onExpand(record, e)}
                          className={classNames(
                            "rotate-0 transform duration-300 cursor-pointer",
                            {
                              "rotate-90": expanded,
                            }
                          )}
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-start space-x-1">
                        <TagIcon size={16} className="text-transparent" />
                        <Right
                          size={18}
                          onClick={e => onExpand(record, e)}
                          className={classNames(
                            "rotate-0 transform duration-300 cursor-pointer",
                            {
                              "rotate-90": expanded,
                            }
                          )}
                        />
                      </div>
                    )}
                  </>
                );
              },
            }}
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No CRM enabled Contact Found"
            description="We couldn’t find any CRM enabled contact. Try creating one."
            primaryButtonProps={{
              label: "New Contact",
              to: "/people",
            }}
          />
        )}
      </TableWrapper>

      <ActivityModal
        activityModalState={activityModalState}
        contactDetail={contactDetail}
        setActivityModalState={setActivityModalState}
        crmActionTagList={crmActionTagList}
        crmActionTagListLoad={crmActionTagListLoad}
        loadContactListResponse={loadContactListResponse}
        setPageIndex={setPageIndex}
        loadCrmActionTagListResponse={loadCrmActionTagListResponse}
      />

      <BulkActivityLogCreateModal
        addActionTagModalState={addActionTagModalState}
        setAddActionTagModalState={setAddActionTagModalState}
        bulkContactIds={selectedRowIds}
        crmActionTagList={crmActionTagList}
        crmActionTagListLoad={crmActionTagListLoad}
        loadCrmActionTagListResponse={loadCrmActionTagListResponse}
        loadContactListResponse={loadContactListResponse}
        setPageIndex={setPageIndex}
      />

      <BulkStaffUpdateModal
        addStaffModalState={addStaffModalState}
        setAddStaffModalState={setAddStaffModalState}
        bulkContactIds={selectedRowIds}
        loadContactListResponse={loadContactListResponse}
        setPageIndex={setPageIndex}
        staffOptionLoad={staffOptionLoad}
      />

      <NoteModal
        noteModalState={noteModalState}
        contactDetail={contactDetail}
        setNoteModalState={setNoteModalState}
        loadContactListResponse={loadContactListResponse}
        setPageIndex={setPageIndex}
      />

      <ExportModal
        isOpen={exportConfirmAlertState}
        onClose={() => setExportConfirmAlertState(false)}
        isLoading={openedActionTagsLoad}
        openedActionTags={openedActionTags}
        exportContactList={() => exportContactList()}
        setBulkTagIds={setBulkTagIds}
        bulkTagIds={bulkTagIds}
      />

      <TagRemovalModal
        isOpen={removeActionTagModalState}
        onClose={() => setRemoveActionTagModalState(false)}
        isLoading={openedActionTagsLoad}
        openedActionTags={openedActionTags}
        destroyContactActivityLogsResponse={destroyContactActivityLogsResponse}
        setBulkTagIds={setBulkTagIds}
        bulkTagIds={bulkTagIds}
        removalLoading={removeActionTagLoad}
      />

      <Alert
        isOpen={closeTagActionState}
        onClose={() => setCloseTagActionState(false)}
        title="Close Action Tag"
        message="Are you sure you want to close this action tag?"
        submitButtonLabel="Yes, continue"
        cancelButtonLabel="No, cancel"
        onSubmit={() => {
          closeActionTag();
        }}
      />

      <Alert
        isOpen={hideAlertState}
        onClose={() => setHideAlertState(false)}
        title="Hide Contact from CRM"
        message="Are you sure you want to hide contact/s from CRM?"
        submitButtonLabel="Yes, continue"
        cancelButtonLabel="No, cancel"
        onSubmit={() => {
          onHide();
        }}
      />

      <FilterPane
        user={user}
        isOpen={filterPaneState}
        onClose={() => setFilterPaneState(false)}
        filterAttr={filterAttr}
        setFilterAttr={setFilterAttr}
        loadContactListResponse={loadContactListResponse}
        loadFilterSpecificationListResponse={
          loadFilterSpecificationListResponse
        }
      />
    </Container>
  );
};

export default Crm;
