import React, { useState } from "react";
import { Label, Button, Textarea, Alert } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { updateJob } from "apis/jobs/jobs";
import { showToastrError } from "common";
import { artistModuleName } from "common/helper";
import StatusDropdown from "../../StatusDropdown";
import Card from "components/Common/Card";

const JobDetails = ({ jobDetail, loadJobDetailResponse }) => {
  const [jobId, setJobId] = useState();
  const [graveyardAlertOpen, setGraveyardAlertOpen] = useState(false);
  const [reason, setReason] = useState("");

  const onStatusUpdate = async (jobId, status) => {
    try {
      await updateJob(jobId, { job: status });
      loadJobDetailResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onGraveyardStatusUpdate = jobId => {
    setGraveyardAlertOpen(true);
    setJobId(jobId);
  };

  return (
    <>
      <Card title="Job Details" className="mb-4 space-y-4">
        <div className="grid grid-cols-5 gap-4">
          <div className="flex items-baseline col-span-3 space-x-2">
            <Label className="whitespace-no-wrap">Job Name:</Label>
            <span className="font-semibold">{jobDetail.name}</span>
          </div>
          <div className="flex items-baseline col-span-2 space-x-2">
            <Label className="whitespace-no-wrap">Job ID:</Label>
            <span className="flex items-center font-semibold">
              {jobDetail.serialNumber}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-4">
          <div className="flex items-baseline col-span-3 space-x-2">
            <Label className="whitespace-no-wrap">Organisation:</Label>
            <Button
              label={jobDetail.organisationName}
              style="link"
              to={`/organisations/${jobDetail.organisationId}`}
            />
          </div>
          <div className="flex items-baseline col-span-2 space-x-2">
            <Label className="whitespace-no-wrap">Client Contact:</Label>
            {jobDetail.contactFullName ? (
              <Button
                label={jobDetail.contactFullName}
                style="link"
                to={`/people/${jobDetail.contactId}`}
              />
            ) : (
              <span className="text-right">N/A</span>
            )}
          </div>
        </div>

        <div className="grid grid-cols-5 gap-4">
          <div className="flex items-baseline col-span-3 space-x-2">
            <Label className="whitespace-no-wrap">Assigned Producer:</Label>
            <div className="flex flex-col items-end">
              {jobDetail.staffFullName ? (
                <Button
                  key={jobDetail.staffId}
                  label={jobDetail.staffFullName}
                  style="link"
                  to={`/staff/${jobDetail.staffId}`}
                />
              ) : (
                <span className="text-right">N/A</span>
              )}
            </div>
          </div>
          <div className="flex items-baseline col-span-2 space-x-2">
            <Label className="whitespace-no-wrap">Enquired On:</Label>
            <div className="flex flex-col items-end">
              <span className="font-semibold">
                {dayjs(jobDetail.enquiryDate).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-baseline col-span-3 space-x-2">
          <Label className="whitespace-no-wrap">Additional Producers:</Label>
          <div className="flex items-center space-x-1">
            {jobDetail.jobsUsers.length > 0 ? (
              jobDetail.jobsUsers.map((jobsUser, index) => {
                return (
                  <Button
                    key={jobsUser.id}
                    label={
                      jobsUser.userName +
                      (index < jobDetail.jobsUsers.length - 1 ? "," : "")
                    }
                    style="link"
                    to={`/staff/${jobsUser.userId}`}
                  />
                );
              })
            ) : (
              <span className="text-right">N/A</span>
            )}
          </div>
        </div>
        <div className="flex items-baseline col-span-3 space-x-2">
          <Label className="whitespace-no-wrap">
            Assigned {artistModuleName()}:
          </Label>
          <div className="flex items-center space-x-1 flex-wrap">
            {jobDetail.artists.map((artist, index) => {
              return (
                <Button
                  key={artist.id}
                  label={
                    artist.name +
                    (index < jobDetail.artists.length - 1 ? "," : "")
                  }
                  style="link"
                  to={`/${artistModuleName().toLowerCase()}/${artist.id}`}
                />
              );
            })}
          </div>
        </div>
        <div className="flex items-baseline col-span-2 space-x-2">
          <Label className="whitespace-no-wrap">Status:</Label>
          <StatusDropdown
            data={jobDetail}
            onStatusUpdate={onStatusUpdate}
            onGraveyardStatusUpdate={onGraveyardStatusUpdate}
          />
        </div>
      </Card>

      <Alert
        isOpen={graveyardAlertOpen}
        title="Move Job to Graveyard"
        message={
          <div className="flex flex-col space-y-3">
            <span>
              Are you sure you want to move this job to graveyard? A job moved
              to graveyard would not be considered for further invoicing and
              estimates.
            </span>
            <Textarea
              label="Reason"
              onChange={e => setReason(e.target.value)}
              value={reason}
            />
          </div>
        }
        onClose={() => setGraveyardAlertOpen(false)}
        onSubmit={() => {
          setGraveyardAlertOpen(false);
          onStatusUpdate(jobId, {
            status: "graveyard",
            cancellation_reason: reason,
          });
        }}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, move to graveyard"
      />
    </>
  );
};

export default JobDetails;
