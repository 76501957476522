import React, { useState, useEffect } from "react";
import { Switch, Input, Textarea } from "@bigbinary/neetoui";
import CountryPhone from "components/Common/CountryPhone";
import CountrySelect from "components/Common/CountrySelect";

const ArtistDetails = ({ formik }) => {
  const [company, setCompany] = useState(formik.values.company);
  const [country, setCountry] = useState();

  useEffect(() => {
    if (country?.value) {
      formik.setFieldValue("country", country?.value);
    } else if (country === null) {
      formik.setFieldValue("country", "");
    }
  }, [country]);

  useEffect(() => {
    setCompany(formik.values.company);
  }, [formik.values.company]);

  const setCompanyValue = value => {
    setCompany(value);
    formik.setFieldValue("company", value);
  };

  return (
    <div
      className="px-6 pt-6 pb-12 space-y-6 overflow-y-auto"
      style={{ height: "calc(100vh - 208px)" }}
    >
      <Switch
        label="Company"
        checked={company}
        onChange={() => {
          setCompanyValue(!company);
          formik.resetForms({ errors: {} });
          return formik.handleChange(!company);
        }}
        value={formik.values.company}
        {...formik.getFieldProps("company")}
      />

      {!company && (
        <Input
          label="Pronouns"
          name="pronouns"
          onChange={formik.handleChange}
          value={formik.values.pronouns}
          error={
            Boolean(formik.touched.pronouns && formik.errors.pronouns) &&
            formik.errors.pronouns
          }
          {...formik.getFieldProps("pronouns")}
        />
      )}
      {company && (
        <>
          <Input
            label="Company Name"
            required={company}
            name="CompanyName"
            onChange={(e) => {
              formik.setFieldValue("companyName", e.target.value);
              formik.setFieldValue("salutation", e.target.value);
            }}
            value={formik.values.companyName}
            autoFocus
            error={
              Boolean(
                formik.touched.companyName && formik.errors.companyName
              ) && formik.errors.companyName
            }
          />

          <Input
            required={company}
            label="Company Email"
            name="companyEmail"
            onChange={formik.handleChange}
            value={formik.values.companyEmail}
            error={
              Boolean(
                formik.touched.companyEmail && formik.errors.companyEmail
              ) && formik.errors.companyEmail
            }
            {...formik.getFieldProps("companyEmail")}
          />
        </>
      )}
      {!company && (
        <>
          <Input
            label="First Name"
            required={!company}
            name="firstName"
            onChange={(e) => {
              formik.setFieldValue("firstName", e.target.value);
              formik.setFieldValue("salutation", e.target.value);
            }}
            value={formik.values.firstName}
            error={
              Boolean(formik.touched.firstName && formik.errors.firstName) &&
              formik.errors.firstName
            }
          />

          <Input
            required={!company}
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={
              Boolean(formik.touched.lastName && formik.errors.lastName) &&
              formik.errors.lastName
            }
            {...formik.getFieldProps("lastName")}
          />
          <Input
            required={!company}
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={
              Boolean(formik.touched.email && formik.errors.email) &&
              formik.errors.email
            }
            {...formik.getFieldProps("email")}
          />

          <Input
            label="Date of Birth"
            name="dateOfBirth"
            onChange={formik.handleChange}
            value={formik.values.dateOfBirth}
            error={
              Boolean(
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
              ) && formik.errors.dateOfBirth
            }
            {...formik.getFieldProps("dateOfBirth")}
          />
        </>
      )}

      <Textarea
        label="Street Address"
        name="streetAddress"
        onChange={formik.handleChange}
        value={formik.values.streetAddress}
        row="3"
        error={
          Boolean(
            formik.touched.streetAddress && formik.errors.streetAddress
          ) && formik.errors.streetAddress
        }
        {...formik.getFieldProps("streetAddress")}
      />
      <Input
        label="City/Suburb"
        name="suburb"
        onChange={formik.handleChange}
        value={formik.values.suburb}
        error={
          Boolean(formik.touched.suburb && formik.errors.suburb) &&
          formik.errors.suburb
        }
        {...formik.getFieldProps("suburb")}
      />
      <Input
        label="State"
        name="state"
        onChange={formik.handleChange}
        value={formik.values.state}
        error={
          Boolean(formik.touched.state && formik.errors.state) &&
          formik.errors.state
        }
        {...formik.getFieldProps("state")}
      />

      <CountrySelect
        label="Country"
        onCountryChange={setCountry}
        country={formik.values.country}
        showCountryName
        showCloseButton
      />

      <Input
        label="Postcode"
        name="postcode"
        onChange={formik.handleChange}
        value={formik.values.postcode}
        error={
          Boolean(formik.touched.postcode && formik.errors.postcode) &&
          formik.errors.postcode
        }
        {...formik.getFieldProps("postcode")}
      />

      <Input
        label="Salutation"
        helpText="Salutation is used in emails."
        onChange={formik.handleChange}
        value={formik.values.salutation}
        error={
          Boolean(formik.touched.salutation && formik.errors.salutation) &&
          formik.errors.salutation
        }
        {...formik.getFieldProps("salutation")}
      />
      {!company && (
        <CountryPhone
          formik={formik}
          required={false}
          selectedCountry={country}
        />
      )}

      {company && (
        <CountryPhone
          formik={formik}
          required={false}
          selectedCountry={country}
          attribute="companyPhone"
        />
      )}
    </div>
  );
};

export default ArtistDetails;
