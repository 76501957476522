import axios from "axios";

const getdJobsUrl = id => {
  if (id) {
    return `/api/v1/job_module/jobs/${id}`;
  }

  return `/api/v1/job_module/jobs`;
};

const direction = (dir) => {
  if (dir === "descend") {
    return "desc"
  } else if (dir === "ascend") {
    return "asc"
  }
}

export const getJobs = (
  searchParams = "",
  sortProps = {},
  pageIndex,
  perPage,
  statusFilter,
  locationFilter,
  jobTypeId
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[name_i_cont_any]=${searchParams}&q[user_full_name_i_cont_any]=${searchParams}&q[job_serial_number_i_cont_any]=${searchParams}&q[organisation_name_i_cont_any]=${searchParams}&q[artists_full_name_i_cont_any]=${searchParams}&q[m]=or`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(sortProps?.order)}`;
  let query = paginationQuery;
  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;
  if (statusFilter.length > 0) query += `&status_filter=${statusFilter}`;
  if (locationFilter.length > 0)
    query += `&location_filter=${locationFilter}`;
  if (jobTypeId?.length > 0) query += `&job_type_filter=${jobTypeId}`;

  return axios.get(`${getdJobsUrl()}?${query}`);
};

export const getJob = id => {
  return axios.get(getdJobsUrl(id));
};

export const createJob = payload => {
  return axios.post(getdJobsUrl(), payload);
};

export const updateJob = (id, payload) => {
  return axios.put(getdJobsUrl(id), payload);
};

export const destroyJob = id => {
  return axios.delete(getdJobsUrl(id));
};

export const bulkDelete = payload => {
  return axios.post(`${getdJobsUrl()}/bulk_delete`, payload);
};

export const bulkClone = payload => {
  return axios.post(`${getdJobsUrl()}/bulk_clone`, payload);
};

export const sendWelcomeEmail = (id, payload) => {
  return axios.post(`${getdJobsUrl(id)}/send_jobs`, payload);
};
