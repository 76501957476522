import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "common/helper";

const getOrganisationsUrl = (id, searchParams, page, id_array) => {
  if (id) {
    return `/api/v1/organisations/organisations/${id}`;
  }

  if (searchParams || id_array) {
    let searchQuery = `q[name_i_cont_any]=${searchParams.replace(
      "&",
      ""
    )}&q[id_eq]=${searchParams}&q[m]=or&q[id_array]=${id_array}`;
    return `/api/v1/organisations/organisations?${searchQuery}&page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
  }

  return `/api/v1/organisations/organisations?page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
};

export const getOrganisations = (searchParams, page = 1, id_array = "") => {
  return axios.get(getOrganisationsUrl("", searchParams, page, id_array));
};

export const getOrganisation = id => {
  return axios.get(getOrganisationsUrl(id));
};

export const createOrganisation = payload => {
  return axios.post(getOrganisationsUrl(""), payload);
};

export const updateOrganisation = (id, payload) => {
  return axios.put(getOrganisationsUrl(id), payload);
};

export const detroyOrganisation = id => {
  return axios.delete(getOrganisationsUrl(id));
};
