import React, { useState, useEffect, useRef } from "react";
import {
  Spinner,
  Typography,
  Dropdown,
  Table,
  Callout,
} from "@bigbinary/neetoui";
import { Container, Header, SubHeader } from "@bigbinary/neetoui/layouts";
import * as dayjs from "dayjs";
import { getRcis } from "apis/rcis/rcis";
import { useUserState } from "contexts/user";
import { showToastrError } from "common";
import { DEFAULT_PAGE_SIZE, getRandomNotFoundImage } from "common/helper";
import { COLUMN_DATA } from "./constants";
import * as R from "ramda";
import useDebounce from "common/debounce";
import EmptyState from "components/Common/EmptyState";
import LocationDropdown from "./../LocationDropdown";

const RciListing = () => {
  const { user } = useUserState();
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [rciList, setRciList] = useState([]);
  const [rciListLoading, setRciListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [xeroTokenExpired, setXeroTokenExpired] = useState(false);
  const [preferredLocation, setPreferredLocation] = useState();
  const [preferredPeriod, setPreferredPeriod] = useState("All Time");
  const [sortProps, setSortProps] = useState();
  const [pageIndex, setPageIndex] = useState();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [initial, setInitial] = useState(true);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (user) {
      setPreferredLocation(user.location_id);
    }
  }, [user]);

  useEffect(() => {
    if (!R.isNil(preferredLocation) && !R.isEmpty(preferredLocation)) {
      loadRciListResponse(true);
    }
  }, [preferredLocation]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadRciListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (sortProps?.column && !initial) {
      loadRciListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadRciListResponse();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (preferredPeriod && !initial) {
      loadRciListResponse();
    }
  }, [preferredPeriod]);

  const loadRciListResponse = async (load = false) => {
    if (load) {
      setRciListLoading(true);
    }

    try {
      const response = await getRcis(
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE,
        preferredLocation === "All" ? "" : preferredLocation,
        preferredPeriod === "All Time"
          ? ""
          : preferredPeriod === "7 days"
          ? dayjs().subtract(7, "day").format("YYYY-MM-DD")
          : dayjs().subtract(preferredPeriod, "month").format("YYYY-MM-DD")
      );
      setRciList(response.data.rcis);
      setTotalRecords(response.data.totalRecords);
      setXeroTokenExpired(response.data.xeroTokenExpired);
      setRciListLoading(false);
    } catch (error) {
      console.log(error);
      showToastrError(error.data.errors[0]);
    }
  };

  if (rciListLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="RCIs"
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(""),
        }}
        actionBlock={
          <LocationDropdown
            location={preferredLocation}
            setLocation={setPreferredLocation}
          />
        }
      />
      <SubHeader
        className="px-6"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1 ? `${totalRecords} RCIs` : `${totalRecords} RCIs`}
          </Typography>
        }
        rightActionBlock={
          <div className="flex flex-row space-x-3">
            <Dropdown
              label={
                preferredPeriod === "All Time"
                  ? "All Time"
                  : preferredPeriod === "7 days"
                  ? `Last 7 days`
                  : `Last ${preferredPeriod} months`
              }
              buttonStyle="text"
              position="bottom-end"
              closeOnSelect
            >
              <Menu>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod("7 days");
                  }}
                >
                  Last 7 days
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(4);
                  }}
                >
                  Last 4 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(6);
                  }}
                >
                  Last 6 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(12);
                  }}
                >
                  Last 12 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(24);
                  }}
                >
                  Last 24 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(36);
                  }}
                >
                  Last 36 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod("All Time");
                  }}
                >
                  All Time
                </MenuItem.Button>
              </Menu>
            </Dropdown>
          </div>
        }
      />
      {!R.isNil(xeroTokenExpired) && !xeroTokenExpired && (
        <div className="px-6 w-full">
          <Callout style="info" className="mb-4">
            Xero Logged in!
          </Callout>
        </div>
      )}

      <div
        className="w-full px-6 mb-6"
        style={{
          height:
            !R.isNil(xeroTokenExpired) && !xeroTokenExpired
              ? "calc(100vh - 257px)"
              : "calc(100vh - 205px)",
        }}
      >
        {!R.isEmpty(rciList) ? (
          <Table
            fixedHeight
            columnData={COLUMN_DATA}
            rowData={rciList}
            totalCount={totalRecords}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) => {
              setInitial(false);
              setSortProps(sorter);
            }}
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No RCIs Found"
            description="We couldn’t find any RCI. Try creating one."
          />
        )}
      </div>
    </Container>
  );
};

export default RciListing;
