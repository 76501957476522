import * as Yup from "yup";
import * as dayjs from "dayjs";

export const Status = [
  { value: "estimate", label: "Estimate" },
  { value: "post", label: "Post" },
  { value: "in_progress", label: "In Progress" },
  { value: "inactive", label: "Inactive" },
  { value: "purgatory", label: "Purgatory" },
  { value: "graveyard", label: "Graveyard" },
  { value: "all", label: "All" },
];

export const JobStatus = [
  { value: "estimate", label: "Estimate" },
  { value: "post", label: "Post" },
  { value: "in_progress", label: "In Progress" },
  { value: "inactive", label: "Inactive" },
  { value: "purgatory", label: "Purgatory" },
  { value: "graveyard", label: "Graveyard" },
];

export const JOB_DETAILS_TAB = {
  tabs: [
    "Overview",
    "Scope",
    "Estimate",
    "Usage",
    "Booking Form",
    "Extras",
    "Messages",
    "RCIs",
    "License",
    "Post Production",
    "Invoices",
    "Logs",
    "Resources",
  ],
};

export const COUNTRY_LIST = [
  { label: "USA", value: "USA", currency: "USD" },
  { label: "UK", value: "UK", currency: "GBP" },
  { label: "AU", value: "AU", currency: "AUD" },
];

export const CURRENCY_LIST = [
  { label: "USD", value: "USD" },
  { label: "GBP", value: "GBP" },
  { label: "AUD", value: "AUD" },
];

export const VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name can't be empty."),
  status: Yup.string().required("Status can't be empty."),
  jobTypeId: Yup.string().required("Job type can't be empty."),
  organisationId: Yup.string().required("Client can't be empty."),
  staffId: Yup.string().required("Staff can't be empty."),
  contactId: Yup.string().when("organisationId", {
    is: organisationId => organisationId?.length > 0,
    then: Yup.string().required("Contact can't be empty."),
  }),
  companyDetailId: Yup.string().required("Company can't be empty."),
  enquiryDate: Yup.date().required("Enquiry date can't be empty."),
});

export const INITIAL_VALUE = {
  name: "",
  status: "estimate",
  jobTypeId: "",
  organisationId: "",
  parentOrganisation: "",
  staffId: "",
  additionalStaffId: "",
  companyDetailId: "",
  contactId: "",
  purchaseOrder: "",
  clientJobNumber: "",
  vendorId: "NA",
  attachments_attributes: "",
  note: "",
  contactIds: [],
  artistIds: [],
  enquiryDate: dayjs(),
};
