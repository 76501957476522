import * as axios from "axios";
import * as dayjs from "dayjs";

const direction = (dir) => {
  if (dir === "descend") {
    return "desc"
  } else if (dir === "ascend") {
    return "asc"
  }
}

export const getContacts = (
  sortProps = {},
  pageIndex,
  perPage,
  filterAttr = {},
  searchParams,
) => {
  let searchQuery = `q1[active_organisation_organisation_type_name_i_cont_any]=${searchParams}&q1[active_organisation_name_i_cont_any]=${searchParams}&q1[full_name_i_cont_any]=${searchParams}&q1[organisation_contacts_user_full_name_i_cont_any]=${searchParams}&q1[active_organisation_contact_title_i_cont_any]=${searchParams}&q1[active_organisation_addresses_country_i_cont_any]=${searchParams}&q1[active_organisation_addresses_state_i_cont_any]=${searchParams}&q1[m]=or`;
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(sortProps?.order)}`;
  let query = paginationQuery;
  if (filterAttr?.length > 0) query += `&${filterAttr}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;
  if (searchParams?.length > 0) query += `&${searchQuery}`;

  return axios.get(`/api/v1/crms/contacts?${query}`);
};

export const exportContacts = (filterAttr = {}, closingTagIds) => {
  return axios.post(`/api/v1/crms/contacts/export?${filterAttr}`, {
    export: { tagIds: closingTagIds, closureDate: dayjs().format("YYYY-MM-DD") },
  });
};

export const updateBulkContacts = (payload) => {
  return axios.put(`api/v1/crms/contacts/1`, payload);
};
