import React, { useState } from "react";
import { Tab, Pane, Button } from "@bigbinary/neetoui";
import { artistModuleName } from "common/helper";
import ArtistDetails from "./ArtistDetailsModal";
import InvoiceDetails from "./InvoiceDetailsModal";
import { TAB_CONSTANTS } from "./constants";

const ArtistModal = ({ formik, setCreateModalState }) => {
  const artistModule = artistModuleName();
  const [currentTab, setCurrentTab] = useState(TAB_CONSTANTS().ArtistDetails);

  const renderTabs = currentTab => {
    switch (currentTab.label) {
      case `${artistModule} Details`:
        return <ArtistDetails formik={formik} />;
      case "Invoice Details":
        return <InvoiceDetails formik={formik} />;
    }
  };

  const renderButtonLabel = currentTab => {
    switch (currentTab.label) {
      case `${artistModule} Details`:
        return "Continue";
      case "Invoice Details":
        return "Submit";
    }
  };

  return (
    <>
      <Pane.Body className="px-0">
        <div className="w-full overflow-hidden">
          <Tab className="px-6">
            {Object.keys(TAB_CONSTANTS()).map((key, index) => {
              const tab = TAB_CONSTANTS()[key];
              const { label, icon } = tab;
              return (
                <Tab.Item
                  icon={icon}
                  key={index}
                  className="px-3 py-4"
                  onClick={() => setCurrentTab(tab)}
                  active={currentTab.label === tab.label}
                >
                  {label}
                </Tab.Item>
              );
            })}
          </Tab>
          <form>{renderTabs(currentTab)}</form>
        </div>
      </Pane.Body>
      <Pane.Footer className="space-x-2">
        <Button
          label={renderButtonLabel(currentTab)}
          onClick={() => {
            if (`${artistModule} Details` === currentTab.label) {
              setCurrentTab(() => TAB_CONSTANTS().InvoiceDetails);
            } else {
              formik.handleSubmit();
            }
          }}
        />
        <Button
          style="text"
          label="Cancel"
          onClick={() => setCreateModalState(false)}
        />
      </Pane.Footer>
    </>
  );
};

export default ArtistModal;
