import React, { useState, useEffect } from "react";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { Spinner, Button, Alert, Toastr } from "@bigbinary/neetoui";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getEstimateTermsAndConditions,
  createEstimateTermsAndCondition,
  updateEstimateTermsAndCondition,
  destroyEstimateTermsAndCondition,
} from "apis/settings/estimate_terms_and_conditions";
import { moveItem } from "apis/settings/move_items";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import useDebounce from "common/debounce";
import {
  TERMS_AND_CONDITION_VALIDATION_SCHEMA,
  TERMS_AND_CONDITION_INITIAL_VALUE,
} from "../constants";
import List from "./List";
import EmptyState from "components/Common/EmptyState";
import FormPane from "./FormPane";

const TermsAndConditions = ({ breadcrumbs }) => {
  const [
    estimateTermsAndConditionList,
    setEstimateTermsAndConditionList,
  ] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [estimateTermsAndCondition, setEstimateTermsAndCondition] = useState(
    {}
  );
  const [modalState, setModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: estimateTermsAndCondition?.id
      ? estimateTermsAndCondition
      : TERMS_AND_CONDITION_INITIAL_VALUE,
    validationSchema: TERMS_AND_CONDITION_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (estimateTermsAndCondition?.id) {
        updateEstimateTermsAndConditionEntry();
      } else {
        createEstimateTermsAndConditionEntry();
      }
    },
  });

  useEffect(() => {
    loadEstimateTermsAndConditionList();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadEstimateTermsAndConditionList();
    }
  }, [debouncedSearchTerm]);

  const loadEstimateTermsAndConditionList = async () => {
    try {
      const response = await getEstimateTermsAndConditions(searchParams);
      setEstimateTermsAndConditionList(
        response.data.estimateTermsAndConditions || []
      );
      setListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createEstimateTermsAndConditionEntry = async () => {
    try {
      const response = await createEstimateTermsAndCondition({
        estimate_terms_and_condition: {
          section: formik.values.section,
          description: formik.values.description,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadEstimateTermsAndConditionList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateEstimateTermsAndConditionEntry = async () => {
    try {
      const response = await updateEstimateTermsAndCondition(
        estimateTermsAndCondition?.id,
        {
          estimate_terms_and_condition: {
            section: formik.values.section,
            description: formik.values.description,
          },
        }
      );
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadEstimateTermsAndConditionList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyEstimateTermsAndConditionEntry = async () => {
    try {
      const response = await destroyEstimateTermsAndCondition(
        estimateTermsAndCondition?.id
      );
      setDeleteAlertState(false);
      Toastr.info(response.data.notice);
      loadEstimateTermsAndConditionList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const moveEstimateTermsAndConditionEntry = async (
    estimateTermsAndConditionId,
    nature,
    place
  ) => {
    try {
      const response = await moveItem(estimateTermsAndConditionId, {
        move_item: {
          resource: "estimate_terms_and_conditions",
          nature: nature,
          place: place,
        },
      });
      Toastr.info(response.data.notice);
      loadEstimateTermsAndConditionList();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const handleNewButtonClick = async () => {
    setButtonLoader(false);
    formik.resetForm();
    setEstimateTermsAndCondition({});
    setModalState(true);
  };

  const handleSubmitButtonClick = async () => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonLoader(true);
      formik.handleSubmit();
    }
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Terms And Conditions"
        breadcrumbs={breadcrumbs}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(),
        }}
        actionBlock={
          <Button label="Add New" onClick={() => handleNewButtonClick()} />
        }
      />

      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(estimateTermsAndConditionList) ? (
          <List
            estimateTermsAndConditionList={estimateTermsAndConditionList}
            setEstimateTermsAndCondition={setEstimateTermsAndCondition}
            setModalState={setModalState}
            setDeleteAlertState={setDeleteAlertState}
            moveEstimateTermsAndConditionEntry={
              moveEstimateTermsAndConditionEntry
            }
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Terms And Conditions Found"
            description="We couldn’t find any terms and conditions. Try creating one."
            primaryButtonProps={{
              label: "Add New",
              onClick: () => handleNewButtonClick(),
            }}
          />
        )}
      </div>

      <FormPane
        isOpen={modalState}
        onClose={() => setModalState(false)}
        formik={formik}
        onSubmit={handleSubmitButtonClick}
        title={
          estimateTermsAndCondition?.id
            ? "Edit Terms and Condition"
            : "Add Terms and Condition"
        }
        buttonLoader={buttonLoader}
      />

      <Alert
        isOpen={deleteAlertState}
        title="Delete Terms and Condition"
        message={
          <>
            Are you sure you want to delete the terms and condition{" "}
            <strong>{estimateTermsAndCondition?.section}</strong>?
          </>
        }
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroyEstimateTermsAndConditionEntry();
        }}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default TermsAndConditions;
