/* eslint-disable react/jsx-filename-extension */

import React from "react";
import routes from "./routes";

import {
  Building,
  Message,
  Invoice,
  Location,
  Draft,
  ListDot,
  Form,
  Goal,
  ListNumber,
  Hours,
  TimeTracking,
  Info,
  Dropdown,
  Customers,
  Design,
  Help,
  Tags,
  Filter,
  Globe,
  Group,
  UserRole,
  UserSettings,
  Chat,
} from "@bigbinary/neeto-icons";

import Milestones from "./Milestones";
import UsageItems from "./Usages";
import TargetAmounts from "./TargetAmounts";
import EstimateLineItems from "./EstimateLineItems";
import UsageTemplates from "./DefaultUsageTemplates";
import ScopeDefaults from "./ScopeDefaults";
import BusinessDetails from "./BusinessDetails";
import DefaultList from "./DefaultList";
import Others from "./Others";
import TemplateSettings from "./TemplateSettings";
import Crm from "./Crm";

export const DEFAULT_TAB = "business_details";

export const SETTINGS_CATAGORIES = [
  {
    name: "BUSINESS_DETAILS",
    label: "Business Details",
    description: "Company and Email Details",
    value: "business_details",
    path: routes.settings.businessDetails,
    component: BusinessDetails,
  },
  {
    name: "DEFAULT_LIST",
    label: "Default List",
    description: "Default Kill Fees, Locations, and Tax Types",
    value: "default_list",
    path: routes.settings.defaultList,
    component: props => <DefaultList {...props} />,
  },
  {
    name: "MILESTONES",
    label: "Milestones",
    description: "Create and manage Milestone templates",
    value: "milestones",
    path: routes.settings.milestones,
    component: props => <Milestones {...props} />,
  },
  {
    name: "USAGE_ITEMS",
    label: "Usage Items",
    description: "Create and manage default Usage items",
    value: "usage_items",
    path: routes.settings.usageItems,
    component: props => <UsageItems {...props} />,
  },
  {
    name: "USAGE_TEMPLATES",
    label: "Usage Templates",
    description: "Create and manage Usage templates",
    value: "usage_templates",
    path: routes.settings.usageTemplates,
    component: props => <UsageTemplates {...props} />,
  },
  {
    name: "TARGET_AMOUNTS",
    label: "Target Amounts",
    description: "Set dashboard approval targets",
    value: "target_amounts",
    path: routes.settings.targetAmounts,
    component: props => <TargetAmounts {...props} />,
  },
  {
    name: "ESTIMATE_LINE_ITEMS",
    label: "Estimate Line Items",
    description: "Categories, Items, and Metrics",
    value: "estimate_line_items",
    path: routes.settings.estimateLineItems,
    component: props => <EstimateLineItems {...props} />,
  },
  {
    name: "SCOPE_DEFAULTS",
    label: "Scope Defaults",
    description: "Set defaults for project and delivery scope",
    value: "scope_defaults",
    path: routes.settings.scopeDefaults,
    component: props => <ScopeDefaults {...props} />,
  },
  {
    name: "TEMPLATE_SETTINGS",
    label: "Template Settings",
    description: "Job Templates and T&Cs",
    value: "template_settings",
    path: routes.settings.templateSettings,
    component: props => <TemplateSettings {...props} />,
  },
  {
    name: "CRM",
    label: "CRM",
    description: "Set Action Tags and Filter Specs",
    value: "crm",
    path: routes.settings.crm,
    component: props => <Crm {...props} />,
  },
  {
    name: "OTHERS",
    label: "Others",
    description: "Organisation Types, Currencies, Job Types, and Agency Groups",
    value: "others",
    path: routes.settings.others,
    component: props => <Others {...props} />,
  },
];

export const BUSINESS_DETAILS_LIST = user => [
  {
    label: "Company Details",
    icon: Building,
    value: "company_details",
    description: "Manage your company details",
    path: routes.settings.companyDetails,
  },
  {
    label: "Email / Paperwork Details",
    icon: Message,
    value: "email_details",
    description: "Manage your email details",
    path: routes.settings.emailDetails,
  },
  user.access === "admin" || user.access === "super_admin"
    ? {
        label: "Accounts",
        icon: UserSettings,
        value: "accounts",
        description: "Manage your accounts",
        path: routes.settings.accounts,
        exact: true,
      }
    : null,
  user.access === "admin" || user.access === "super_admin"
    ? {
        label: "Xero Account",
        icon: UserSettings,
        value: "xero_sessions",
        description: "Manage Xero Integration",
        path: routes.settings.xero_sessions,
        exact: true,
      }
    : null,
];

export const DEFAULT_LIST_LIST = [
  {
    label: "Default Kill Fees",
    icon: Invoice,
    value: "default_kill_fees",
    description: "Manage your default kill fees",
    path: routes.settings.defaultKillFees,
  },
  {
    label: "Default Locations",
    icon: Location,
    value: "default_locations",
    description: "Manage your default locations",
    path: routes.settings.defaultLocations,
  },
  {
    label: "Default Tax Types",
    icon: Draft,
    value: "default_tax_types",
    description: "Manage your default tax types",
    path: routes.settings.defaultTaxTypes,
  },
];

export const USAGE_LIST = [
  {
    label: "Categories",
    icon: ListDot,
    value: "categories",
    description: "Manage your categories",
    path: routes.settings.categories,
  },
  {
    label: "Details",
    icon: Form,
    value: "details",
    description: "Manage your details",
    path: routes.settings.details,
  },
  {
    label: "Quantities",
    icon: ListNumber,
    value: "quantities",
    description: "Manage your quantities",
    path: routes.settings.quantities,
  },
  {
    label: "Territories",
    icon: Location,
    value: "territories",
    description: "Manage your territories",
    path: routes.settings.territories,
  },
  {
    label: "Periods",
    icon: Hours,
    value: "periods",
    description: "Manage your periods",
    path: routes.settings.periods,
  },
  {
    label: "Exclusivity Periods",
    icon: TimeTracking,
    value: "exclusivity_periods",
    description: "Manage your exclusivity periods",
    path: routes.settings.exclusivityPeriods,
  },
];

export const ESTIMATE_LINE_ITEMS_LIST = [
  {
    label: "Line Item Categories",
    icon: ListDot,
    value: "line_item_categories",
    description: "Manage your line item categories",
    path: routes.settings.lineItemCategories,
  },
  {
    label: "Estimate Line Items",
    icon: Form,
    value: "estimate_line_items",
    description: "Manage your estimate line items",
    path: routes.settings.estimateLineItemCategories,
  },
  {
    label: "Metrics",
    icon: Goal,
    value: "metrics",
    description: "Manage your metrics",
    path: routes.settings.metrics,
  },
];

export const REVISION_SCOPE_LIST = [
  {
    label: "Project Scopes",
    icon: Dropdown,
    value: "project_scopes",
    description: "Manage your project scopes",
    path: routes.settings.projectScopes,
  },
  {
    label: "Delivery Scopes",
    icon: Customers,
    value: "delivery_scopes",
    description: "Manage your delivery scopes",
    path: routes.settings.deliveryScopes,
  },
];

export const TEMPLATE_SETTINGS_LIST = [
  {
    label: "Templates",
    icon: Design,
    value: "job_templates",
    description: "Add/Manage custom attributes for the templates",
    path: routes.settings.jobTemplates,
  },
  {
    label: "Chat Templates",
    icon: Chat,
    value: "chat_templates",
    description: "Add/Manage templates to reply of your chats.",
    path: routes.settings.chatTemplates,
  },
  {
    label: "Terms & Conditions",
    icon: Help,
    value: "terms_and_conditions",
    description: "Manage your terms and conditions",
    path: routes.settings.termsAndConditions,
  },
  {
    label: "Special Job Conditions",
    icon: Info,
    value: "special_job_conditions",
    description: "Manage your special job conditions",
    path: routes.settings.specialJobConditions,
  },
];

export const CRM_LIST = [
  {
    label: "CRM Action Tags",
    icon: Tags,
    value: "crm_action_tags",
    description: "Manage your CRM action tags",
    path: routes.settings.crmActionTags,
  },
  {
    label: "Filter Specifications",
    icon: Filter,
    value: "filter_specifications",
    description: "Manage your filter specifications",
    path: routes.settings.filterSpecifications,
  },
];

export const OTHERS_LIST = [
  {
    label: "Organisation Types",
    icon: Globe,
    value: "organisation_types",
    description: "Manage your organisation types",
    path: routes.settings.organisationTypes,
  },
  {
    label: "Currencies",
    icon: Invoice,
    value: "currencies",
    description: "Manage your currencies",
    path: routes.settings.currencies,
  },
  {
    label: "Job Types",
    icon: UserRole,
    value: "job_types",
    description: "Manage your job types",
    path: routes.settings.jobTypes,
  },
  {
    label: "Agency Groups",
    icon: Group,
    value: "agency_groups",
    description: "Manage your agency groups",
    path: routes.settings.agencyGroups,
  },
];

export const COUNTRY_LIST = [
  {
    label: "USA",
    value: "USA",
  },
  {
    label: "UK",
    value: "UK",
  },
  {
    label: "AU",
    value: "AU",
  },
];
