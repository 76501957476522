import React, { useState, useEffect } from "react";
import { Spinner, Toastr } from "@bigbinary/neetoui";
import { useUserState } from "contexts/user";
import {
  approvalMailToProducer,
  approvalQuestionMail,
} from "apis/email_templates/estimate_approvals";
import { getEstimateBuilder } from "apis/email_templates/builders";
import { getEstimatePdf } from "apis/email_templates/pdfs";
import { InView } from "react-intersection-observer";
import {
  Approval,
  CostSummary,
  CreationFees,
  Overview,
  ProjectPhases,
  TermsandConditions,
  Thankyou,
  UsageOptions,
  Welcome,
} from "../Pages";
import PrintSave from "../Shared/PrintSave";
import ApproveQuestionButton from "../Shared/ApproveQuestionButton";
import "../Styles/common";
import prntr from "prntr";
import { useLocation } from "react-router-dom";
import { showToastrError } from "common";
import { numberWithCommas } from "common/helper";
import { CURRENCY_SYMBOL } from "../constants";
import { EMAIL_DISABLED_MESSAGE } from "common/constants";

const Estimate = () => {
  document.title = "Estimate";
  const [inView, setInView] = useState(false);
  const location = useLocation();
  const { user } = useUserState();
  const paramToken = location?.search?.split("?token=")[1];
  const [jobDetail, setJobDetail] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [revision, setRevision] = useState();
  const [projectScopes, setProjectScopes] = useState([]);
  const [deliveryScopes, setDeliveryScopes] = useState([]);
  const [jobPhases, setJobPhases] = useState([]);
  const [killFees, setKillFees] = useState([]);
  const [timingList, setTimingList] = useState([]);
  const [revisionItems, setRevisionItems] = useState([]);
  const [usages, setUsages] = useState([]);
  const [selectedUsageId, setSelectedUsageId] = useState();
  const [emailBackground, setEmailBackground] = useState();
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [defaultTemplateSettingLoad, setDefaultTemplateSettingLoad] = useState(
    false
  );
  const [templateTermsAndConditions, setTemplateTermsAndConditions] = useState(
    []
  );
  const [
    templateTermsAndConditionsLoad,
    setTemplateTermsAndConditionsLoad,
  ] = useState(true);
  const [
    jobTypeSpecificTemplateSetting,
    setJobTypeSpecificTemplateSetting,
  ] = useState();
  const [
    jobTypeSpecificTemplateSettingLoad,
    setJobTypeSpecificTemplateSettingLoad,
  ] = useState();

  const [companyDetailLoad, setCompanyDetailLoad] = useState(true);
  const [jobDetailLoad, setJobDetailLoad] = useState();
  const [revisionLoad, setRevisionLoad] = useState();
  const [projectScopesLoad, setProjectScopesLoad] = useState([]);
  const [deliveryScopesLoad, setDeliveryScopesLoad] = useState([]);
  const [jobPhasesLoad, setJobPhasesLoad] = useState([]);
  const [killFeeLoad, setKillFeeLoad] = useState(true);
  const [timingLoad, setTimingLoad] = useState(true);
  const [revisionItemsLoad, setRevisionItemsLoad] = useState([]);
  const [usagesLoad, setUsagesLoad] = useState(true);
  const [usageAmount, setUsageAmount] = useState(0);
  const [usageTitle, setUsageTitle] = useState("");
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [pdfLoad, setPdfLoad] = useState(false);
  const [mailLoad, setMailLoad] = useState(false);

  const setResourceForPdfFilename = name => {
    switch (name) {
      case "[job_serial]":
        return jobDetail?.serialNumber;
      case "[org_serial]":
        return jobDetail?.organisationSerial;
      case "[paperwork_serial]":
        return revision?.serialNumber;
    }
  };

  const pdfFileName = () => {
    let fileName = "";
    const filenameComponents = emailBackground?.pdfFileName?.split("<>");

    filenameComponents?.map(component => {
      if (component.includes("[")) {
        fileName += setResourceForPdfFilename(component);
      } else {
        fileName += component;
      }
    });

    return fileName;
  };

  useEffect(() => {
    getEstimateBuilderRecords();
  }, [paramToken]);

  useEffect(() => {
    if (usages) {
      setSelectedUsageId(usages.find(usage => usage.kind === "master")?.id);
    }
  }, [usages]);

  useEffect(() => {
    if (selectedUsageId) {
      const amount = usages.find(usage => usage.id === selectedUsageId)?.fee;
      setUsageAmount(Number(amount).toFixed(2));
      setUsageTitle(usages.find(usage => usage.id === selectedUsageId)?.title);
    }
  }, [selectedUsageId]);

  const getEstimateBuilderRecords = async () => {
    try {
      const response = await getEstimateBuilder(paramToken, "");
      setJobDetail(response.data.job);
      setRevision(response.data.estimateRevision);
      setProjectScopes(response.data.projectScopes);
      setDeliveryScopes(response.data.deliveryScopes);
      setJobPhases(response.data.jobPhases);
      setKillFees(response.data.killFees);
      setTimingList(response.data.timings);
      setRevisionItems(response.data.estimateRevisionItems);
      setEmailBackground(response.data.emailBackgroundDetail);
      setUsages(response.data.usages);
      setCompanyDetails(response.data.companyDetails);
      setCompanyDetailLoad(false);
      setDefaultTemplateSetting(
        response.data.templateSettings.find(
          template => template.default === true
        )
      );
      setTemplateTermsAndConditions(response.data.templateTermAndConditions);
      setTemplateTermsAndConditionsLoad(false);
      setDefaultTemplateSettingLoad(false);
      setJobTypeSpecificTemplateSetting(
        response.data.templateSettings.find(
          template => template.jobTypeId === response.data.job.jobTypeId
        )
      );
      setJobTypeSpecificTemplateSettingLoad(false);

      setJobDetailLoad(false);
      setRevisionLoad(false);
      setProjectScopesLoad(false);
      setDeliveryScopesLoad(false);
      setJobPhasesLoad(false);
      setKillFeeLoad(false);
      setTimingLoad(false);
      setRevisionItemsLoad(false);
      setEmailBackgroundLoad(false);
      setUsagesLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const totalWithUsage = fee => {
    return Number(creativeFees()) + Number(fee);
  };

  const totalCost = () => {
    return `${Number(
      Number(totalWithUsage(usageAmount || 0)) +
        Number(agencyServiceFeeWithDiffUsage(usageAmount || 0))
    ).toFixed(2)}`;
  };

  const agencyServiceFeeWithDiffUsage = usageFee => {
    let asfItem = revisionItems.filter(
      item => item.feeType === "agency_service_fee"
    )[0];
    let feePercentage = asfItem?.markupPercentage || 0;

    return (
      (Number(creativeFees()) + Number(usageFee)) *
        (Number(feePercentage) / 100) +
      Number(asfItem?.adjustAmount)
    );
  };

  const currencySymbol = currency => {
    if (CURRENCY_SYMBOL[currency] !== undefined) {
      return CURRENCY_SYMBOL[currency];
    } else {
      return "";
    }
  };

  const ammountWithCurrency = amount => {
    return `${currencySymbol(revision?.currency)}${numberWithCommas(
      Number(amount).toFixed(2)
    )} ${revision?.currency}`;
  };

  const creativeFees = () => {
    return revisionItems
      .filter(item => item.feeType === "artwork")
      .reduce((acc, item) => acc + Number(item.total), 0);
  };

  const getAgencyServiceFee = () => {
    let asfItem = revisionItems.filter(
      item => item.feeType === "agency_service_fee"
    )[0];
    return Number(asfItem?.customRate) + Number(asfItem?.adjustAmount);
  };

  const phasePercentage = percentage => {
    if (percentage === "N/A") {
      return 0;
    } else {
      return (Number(revision?.estimateTotal) * Number(percentage)) / 100;
    }
  };

  const percentageValue = percentage => {
    if (percentage === null) {
      return 0;
    } else {
      return `${percentage}%`;
    }
  };

  const taxAmount = () => {
    return Number(totalCost() * revision?.taxPercentage / 100).toFixed(2)
  }

  const costSummaryData = [
    {
      title: "Creation Fees",
      amount: ammountWithCurrency(creativeFees()),
      bgColor: "#FFEFFF",
      textColor: "#000",
    },
    revision?.usageEnabled ? {
      title: "Usage",
      amount: usageAmount ? ammountWithCurrency(usageAmount) : "",
      bgColor: "#D6EEFF",
      textColor: "#000",
    } : undefined,
    {
      title: "Agency Service Fee",
      amount: usageAmount
        ? ammountWithCurrency(agencyServiceFeeWithDiffUsage(usageAmount))
        : ammountWithCurrency(getAgencyServiceFee()),
      bgColor: "#EDE0D4",
      textColor: "#000",
    },
    revision?.taxType?.length > 0 ?  {
      title: "Subtotal",
      amount: ammountWithCurrency(totalCost()),
      bgColor: "#D1D3D4",
      textColor: "#000",
    } : undefined,
    revision?.taxType?.length > 0 ? {
      title: revision?.taxType,
      amount: ammountWithCurrency(taxAmount()),
      bgColor: "#D1D3D4",
      textColor: "#000",
    } : undefined,
  ].filter(item => item !== undefined);

  const cancellationFeeOverrideText =
    jobTypeSpecificTemplateSetting?.cancellationFeeOverride?.length > 0
      ? jobTypeSpecificTemplateSetting.cancellationFeeOverride
      : defaultTemplateSetting?.defaultCancellationText;

  const projectPhasesData = () => {
    return {
      heads: ["Project Phase", "% of total", "Cost"],
      rows: killFees?.map((phase, index) => {
        return {
          values: [
            <div key="1" className="flex items-center">
              <div className="flex flex-none">
                <div className="flex items-center justify-center w-6 h-6 text-white bg-black rounded-full">
                  {index + 1}
                </div>
              </div>
              <div className="pl-5">{phase.name}</div>
            </div>,
            <div key="2" className="font-bold">
              {percentageValue(phase.percentage)}
            </div>,
            ammountWithCurrency(phasePercentage(phase.percentage)),
          ],
        };
      }),
    };
  };

  const sendApprovalMailToProduction = async () => {
    try {
      setMailLoad(true);
      const response = await approvalMailToProducer(
        paramToken,
        selectedUsageId,
      );

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setMailLoad(false);
    }
  };

  const sendApprovalQuestionMailToProduction = async () => {
    try {
      setMailLoad(true);
      const response = await approvalQuestionMail(paramToken);
      setMailLoad(false);

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getEstimatePdfRecordsPrint = async () => {
    setPdfLoad(true);
    try {
      const response = await getEstimatePdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${pdfFileName() || "estimate"}.pdf`;
        link.click();
      } else {
        prntr({
          printable: url,
          type: "pdf",
          showModal: true,
        });
      }

      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const isIE11 = navigator.userAgent.indexOf("Trident/") > -1;

  document.addEventListener(
    "keydown",
    function keyDownHandler(event) {
      // Watch for `metaKey` too (⌘ in Mac)
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        // Is the browser IE11?
        if (isIE11) {
          const end = Date.now() + 4000; // block the main thread for 4s.
          while (Date.now() < end) {
            // Deliberately no-op
          }
        }

        event.preventDefault();
        event.stopImmediatePropagation();
        getEstimatePdfRecordsPrint();
      }
    },
    isIE11 ? { capture: true } : true
  );

  const getEstimatePdfRecords = async () => {
    setPdfLoad(true);
    try {
      const response = await getEstimatePdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${pdfFileName() || "estimate"}.pdf`;
      link.click();
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const templateLogo = () => {
    return jobTypeSpecificTemplateSetting?.attachments?.find(
      attachment => attachment.attachable_kind === "template_logo"
    );
  };

  const jobLogo = () => {
    if (templateLogo()) {
      return templateLogo().src;
    } else {
      return defaultTemplateSetting?.attachments?.find(
        attachment => attachment.attachable_kind === "pdf_footer_logo"
      )?.src;
    }
  };

  if (
    jobDetailLoad ||
    revisionLoad ||
    projectScopesLoad ||
    deliveryScopesLoad ||
    jobPhasesLoad ||
    revisionItemsLoad ||
    usagesLoad ||
    emailBackgroundLoad ||
    pdfLoad ||
    mailLoad ||
    killFeeLoad ||
    companyDetailLoad ||
    defaultTemplateSettingLoad ||
    jobTypeSpecificTemplateSettingLoad ||
    timingLoad ||
    templateTermsAndConditionsLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="relative w-full px-4 py-8 bg-white lg:p-8 jw-template">
      <div className="flex flex-col w-full h-full">
        <Welcome
          jobDetail={jobDetail}
          revision={revision}
          emailBackground={emailBackground}
          jobLogo={jobLogo}
          templateLogo={templateLogo}
        />
        <div className="flex flex-col w-full divide-y divide-black">
          <Overview
            jobDetail={jobDetail}
            projectScopes={projectScopes}
            deliveryScopes={deliveryScopes}
            revision={revision}
            timingList={timingList}
            emailBackground={emailBackground}
            ammountWithCurrency={ammountWithCurrency}
          />
          <InView onChange={setInView}>
            {({ ref }) => (
              <div
                ref={ref}
                className="flex flex-col w-full divide-y divide-black"
              >
                <CostSummary
                  costSummaryData={costSummaryData}
                  revision={revision}
                  totalCost={ammountWithCurrency(Number(totalCost()) + Number(taxAmount()))}
                  selectedUsageId={selectedUsageId}
                />
                <CreationFees
                  lineItemCategories={revision?.revisionLineItemCategories}
                  parentLessItemCount={revision?.parentLessItemCount}
                  revisionItems={revisionItems}
                  ammountWithCurrency={ammountWithCurrency}
                  creativeFees={creativeFees}
                  description={emailBackground?.approvedFeeDetails}
                />
                {revision?.jobPhaseEnabled && jobPhases?.length > 0 && (
                  <ProjectPhases
                    data={revision?.jobPhaseEnabled ? jobPhases : []}
                    description={emailBackground?.phasesDetails}
                  />
                )}
                {revision?.usageEnabled && (
                  <UsageOptions
                    usages={usages}
                    ammountWithCurrency={ammountWithCurrency}
                    selectedUsageId={selectedUsageId}
                    setSelectedUsageId={setSelectedUsageId}
                    totalCost={totalCost}
                    revision={revision}
                    selectedUsageAmount={usageAmount}
                    description={emailBackground?.usageDetails}
                  />
                )}

                <TermsandConditions
                  defaultTemplateSetting={defaultTemplateSetting}
                  templateTermsAndConditions={templateTermsAndConditions}
                  jobTypeSpecificTemplateSetting={
                    jobTypeSpecificTemplateSetting
                  }
                  projectPhasesData={projectPhasesData}
                  cancellationFeeOverrideText={cancellationFeeOverrideText}
                  specialConditions={jobDetail.specialConditions}
                />
                <Approval
                  costSummaryData={costSummaryData}
                  revision={revision}
                  totalCost={ammountWithCurrency(Number(totalCost()) + Number(taxAmount()))}
                  sendApprovalMailToProduction={sendApprovalMailToProduction}
                  sendApprovalQuestionMailToProduction={
                    sendApprovalQuestionMailToProduction
                  }
                  selectedUsageId={selectedUsageId}
                  emailBackground={emailBackground}
                />
                <Thankyou
                  illustratorAuthorLink={emailBackground?.artistUrl}
                  illustratorAuthorName={emailBackground?.artistName}
                  footerLogo={defaultTemplateSetting?.attachments?.find(
                    attachment =>
                      attachment.attachable_kind === "pdf_footer_logo"
                  )}
                  companyDetails={companyDetails}
                  jobDetail={jobDetail}
                  emailBackground={emailBackground}
                />
              </div>
            )}
          </InView>
        </div>
      </div>
      <PrintSave
        isVisible={inView}
        getPdfRecordsPrint={getEstimatePdfRecordsPrint}
        getPdfRecords={getEstimatePdfRecords}
      />
      <ApproveQuestionButton
        isVisible={inView}
        sendApprovalMailToProduction={sendApprovalMailToProduction}
        sendApprovalQuestionMailToProduction={
          sendApprovalQuestionMailToProduction
        }
      />
    </div>
  );
};

export default Estimate;
