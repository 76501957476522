import React, { useState, useEffect } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import {
  Tag,
  Input,
  Button,
  Select,
  DatePicker,
  Textarea,
  Spinner,
  Alert,
  Typography,
  TimePicker,
} from "@bigbinary/neetoui";
import { TimezoneSelect } from "@blueprintjs/datetime2";
import * as dayjs from "dayjs";
import produce from "immer";
import { updateEstimateRevision } from "apis/jobs/estimate_revisions";
import { getJobPhases, destroyPhase } from "apis/jobs/job_phases";
import { showToastrError } from "common";
import { TIMELINE_TYPES } from "./constants";

const PhaseItem = ({
  revisionId,
  jobDetail,
  selectedPhaseType,
  addPhase,
  initial,
  setPhaseCount,
  setAddPhase,
}) => {
  var utc = require("dayjs/plugin/utc");
  var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
  var customFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const timeFormat = "hh:mm A";
  const [deletingPhaseId, setDeletingPhaseId] = useState("");
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [phaseItemList, setphaseItemList] = useState([]);
  const [phaseItemListLoading, setphaseItemListLoading] = useState(true);
  const [jobPhaseListLoading, setJobPhaseListLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const defaultTimezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta"
      ? "Asia/Kolkata"
      : Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    loadPhaseList(selectedPhaseType.id);
  }, [selectedPhaseType.id]);

  useEffect(() => {
    if (addPhase && !initial && !phaseItemListLoading) {
      setphaseItemList(() => {
        return [
          ...phaseItemList,
          {
            name: "",
            approvalTimelineType: "text_field",
            approvalTimeline: "",
            deliveryTimelineType: "text_field",
            deliveryTimeline: "",
            description: "",
            revision: "",
            approvalTimezone: defaultTimezone,
            deliveryTimezone: defaultTimezone,
          },
        ];
      });
    }
  }, [addPhase, phaseItemListLoading]);

  const updateRevisionPhase = async () => {
    try {
      setJobPhaseListLoading(true);
      setphaseItemListLoading(true);
      let payload = {
        estimate_revision: { job_phases_attributes: phaseItemList },
      };
      const { data } = await updateEstimateRevision(
        jobDetail.id,
        revisionId,
        payload
      );
      setphaseItemListLoading(false);
      setPhaseCount(data.phase_count);
      setAddPhase(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      loadPhaseList();
    }
  };

  const loadPhaseList = async () => {
    try {
      const response = await getJobPhases(revisionId, selectedPhaseType.id);
      setphaseItemList(
        response.data.jobPhases.map(phase => {
          return {
            ...phase,
            approvalTimezone: phase.approvalTimezone || defaultTimezone,
            deliveryTimezone: phase.deliveryTimezone || defaultTimezone,
          };
        })
      );
      setphaseItemListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const destroyPhaseEntry = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyPhase(revisionId, deletingPhaseId);
      setPhaseCount(data.phase_count);
      setDeleteModalState(false);
      loadPhaseList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoader(false);
    }
  };

  if (phaseItemListLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {phaseItemList.map((item, index) => {
        return (
          <div className="relative mt-4" key={index}>
            <div className="p-4 bg-white rounded-md">
              <div className="flex w-full mb-6 space-x-2">
                <Input
                  label="Name"
                  value={item.name}
                  required={true}
                  onChange={e => {
                    setJobPhaseListLoading(false);
                    setphaseItemList(
                      produce(draft => {
                        draft[index] = {
                          ...draft[index],
                          name: e.target.value,
                        };
                      })
                    );
                  }}
                  prefix={<Tag label={index + 1} />}
                  error={
                    phaseItemList[index].name?.length > 0
                      ? ""
                      : "Can't be Empty"
                  }
                />

                <div className="flex items-end justify-end">
                  <Button
                    icon={Delete}
                    style="danger-text"
                    loading={btnLoader}
                    onClick={() => {
                      if (item.id) {
                        setDeletingPhaseId(item.id);
                        setDeleteModalState(true);
                      } else {
                        setphaseItemList(state => {
                          let newState = [...state];
                          newState.pop();
                          return newState;
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 mb-6">
                <div className="flex flex-col">
                  <div className="flex items-end space-x-2 w-full">
                    <div className="w-1/4">
                      <Select
                        label="Delivery Timeline"
                        options={TIMELINE_TYPES}
                        value={TIMELINE_TYPES.find(
                          type => type.value === item.deliveryTimelineType
                        )}
                        onChange={option => {
                          setJobPhaseListLoading(false);
                          setphaseItemList(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                deliveryTimelineType: option.value,
                              };
                            })
                          );
                          if (option.value === "date_time") {
                            setphaseItemList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  deliveryTime: "05:00 PM",
                                };
                              })
                            );
                          }
                        }}
                      />
                    </div>

                    {item.deliveryTimelineType === "date_time" && (
                      <>
                        <div className="w-1/4">
                          <TimezoneSelect
                            showLocalTimezone={true}
                            value={item?.deliveryTimezone}
                            fill={true}
                            popoverProps={{
                              rootBoundary: "viewport",
                              interactionKind: "hover",
                              position: "top",
                              popoverClassName: "p-2 rounded-md bg-white",
                            }}
                            onChange={option => {
                              setJobPhaseListLoading(false);
                              setphaseItemList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    deliveryTimezone: option,
                                  };
                                })
                              );
                            }}
                          >
                            <Input
                              label="Timezone"
                              value={item.deliveryTimezone}
                            />
                          </TimezoneSelect>
                        </div>

                        <div className="w-1/4">
                          <DatePicker
                            label="Date"
                            className="w-full"
                            dateFormat="DD/MM/YYYY"
                            value={
                              item.deliveryDate
                                ? dayjs(item.deliveryDate, "DD MMMM YYYY")
                                : null
                            }
                            onChange={date => {
                              setJobPhaseListLoading(false);
                              setphaseItemList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    deliveryDate: date
                                      ? date.format("DD MMMM YYYY")
                                      : null,
                                  };
                                })
                              );
                            }}
                          />
                        </div>

                        <div className="w-1/4">
                          <TimePicker
                            label="Time"
                            className="w-full"
                            format={timeFormat}
                            use12Hours={true}
                            minuteStep={15}
                            value={
                              item.deliveryTime
                                ? dayjs(item.deliveryTime, timeFormat)
                                : null
                            }
                            onChange={date => {
                              setJobPhaseListLoading(false);
                              setphaseItemList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    deliveryTime: date
                                      ? date.format(timeFormat)
                                      : null,
                                  };
                                })
                              );
                            }}
                          />
                        </div>
                      </>
                    )}

                    {item.deliveryTimelineType === "date_range" && (
                      <div className="w-3/4">
                        <DatePicker
                          type="range"
                          shortcuts={false}
                          highlightCurrentDay={true}
                          includeTime={false}
                          dateFormat="DD/MM/YYYY"
                          value={[
                            item.deliveryTimelineStartDate
                              ? dayjs(
                                  item.deliveryTimelineStartDate,
                                  "YYYY-MM-DD"
                                )
                              : null,
                            item.deliveryTimelineEndDate
                              ? dayjs(
                                  item.deliveryTimelineEndDate,
                                  "YYYY-MM-DD"
                                )
                              : null,
                          ]}
                          label="Date Range"
                          onChange={newRange => {
                            setJobPhaseListLoading(false);
                            setphaseItemList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  deliveryTimelineStartDate: newRange
                                    ? dayjs(newRange[0]).format("YYYY-MM-DD")
                                    : null,
                                  deliveryTimelineEndDate: newRange
                                    ? newRange[1].format("YYYY-MM-DD")
                                    : null,
                                };
                              })
                            );
                          }}
                        />
                      </div>
                    )}

                    {item.deliveryTimelineType === "text_field" && (
                      <div className="w-3/4">
                        <Input
                          label="Text"
                          value={item.deliveryTimeline}
                          onChange={e => {
                            setJobPhaseListLoading(false);
                            setphaseItemList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  deliveryTimeline: e.target.value,
                                };
                              })
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>

                  {item.deliveryDate &&
                    item.deliveryTime &&
                    item.deliveryTimelineType === "date_time" && (
                      <Typography style="body3" className="text-gray-500 mt-1">
                        In your time it will be{" "}
                        {dayjs
                          .tz(
                            dayjs(
                              `${item.deliveryDate} ${item.deliveryTime}`,
                              `DD MMMM YYYY ${timeFormat}`
                            ).format("YYYY-MM-DDTHH:mm"),
                            item.deliveryTimezone
                          )
                          .tz(defaultTimezone)
                          .format("DD MMMM YYYY, hh:mm A")}
                        .
                      </Typography>
                    )}
                </div>

                <div className="flex flex-col">
                  <div className="flex items-end space-x-2 w-full">
                    <div className="w-1/4">
                      <Select
                        label="Feedback/Approval Timeline"
                        options={TIMELINE_TYPES}
                        value={TIMELINE_TYPES.find(
                          type => type.value === item.approvalTimelineType
                        )}
                        onChange={option => {
                          setJobPhaseListLoading(false);
                          setphaseItemList(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                approvalTimelineType: option.value,
                              };
                            })
                          );
                          if (option.value === "date_time") {
                            setphaseItemList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  approvalTime: "05:00 PM",
                                };
                              })
                            );
                          }
                        }}
                      />
                    </div>

                    {item.approvalTimelineType === "date_time" && (
                      <>
                        <div className="w-1/4">
                          <TimezoneSelect
                            showLocalTimezone={true}
                            value={item?.approvalTimezone}
                            popoverProps={{
                              rootBoundary: "viewport",
                              interactionKind: "hover",
                              position: "top",
                              popoverClassName: "p-2 rounded-md bg-white",
                            }}
                            onChange={option => {
                              setJobPhaseListLoading(false);
                              setphaseItemList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    approvalTimezone: option,
                                  };
                                })
                              );
                            }}
                          >
                            <Input
                              label="Timezone"
                              value={item.approvalTimezone}
                            />
                          </TimezoneSelect>
                        </div>

                        <div className="w-1/4">
                          <DatePicker
                            label="Date"
                            className="w-full"
                            dateFormat="DD/MM/YYYY"
                            value={
                              item.approvalDate
                                ? dayjs(item.approvalDate, "DD MMMM YYYY")
                                : null
                            }
                            onChange={date => {
                              setJobPhaseListLoading(false);
                              setphaseItemList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    approvalDate: date
                                      ? date.format("DD MMMM YYYY")
                                      : null,
                                  };
                                })
                              );
                            }}
                          />
                        </div>

                        <div className="w-1/4">
                          <TimePicker
                            label="Time"
                            className="w-full"
                            format={timeFormat}
                            use12Hours={true}
                            minuteStep={15}
                            value={
                              item.approvalTime
                                ? dayjs(item.approvalTime, timeFormat)
                                : null
                            }
                            onChange={date => {
                              setJobPhaseListLoading(false);
                              setphaseItemList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    approvalTime: date
                                      ? date.format(timeFormat)
                                      : null,
                                  };
                                })
                              );
                            }}
                          />
                        </div>
                      </>
                    )}

                    {item.approvalTimelineType === "date_range" && (
                      <div className="w-3/4">
                        <DatePicker
                          type="range"
                          label="Date Range"
                          dateFormat="DD/MM/YYYY"
                          includeTime={false}
                          value={[
                            item.approvalTimelineStartDate
                              ? dayjs(
                                  item.approvalTimelineStartDate,
                                  "YYYY-MM-DD"
                                )
                              : null,
                            item.approvalTimelineEndDate
                              ? dayjs(
                                  item.approvalTimelineEndDate,
                                  "YYYY-MM-DD"
                                )
                              : null,
                          ]}
                          onChange={newRange => {
                            setJobPhaseListLoading(false);
                            setphaseItemList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  approvalTimelineStartDate: newRange
                                    ? newRange[0].format("YYYY-MM-DD")
                                    : null,
                                  approvalTimelineEndDate: newRange
                                    ? newRange[1].format("YYYY-MM-DD")
                                    : null,
                                };
                              })
                            );
                          }}
                        />
                      </div>
                    )}

                    {item.approvalTimelineType === "text_field" && (
                      <div className="w-3/4">
                        <Input
                          label="Text"
                          value={item.approvalTimeline}
                          onChange={e => {
                            setJobPhaseListLoading(false);
                            setphaseItemList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  approvalTimeline: e.target.value,
                                };
                              })
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>

                  {item.approvalDate &&
                    item.approvalTime &&
                    item.approvalTimelineType === "date_time" && (
                      <Typography style="body3" className="text-gray-500 mt-1">
                        In your time it will be{" "}
                        {dayjs
                          .tz(
                            dayjs(
                              `${item.approvalDate} ${item.approvalTime}`,
                              `DD MMMM YYYY ${timeFormat}`
                            ).format("YYYY-MM-DDTHH:mm"),
                            item.approvalTimezone
                          )
                          .tz(defaultTimezone)
                          .format("DD MMMM YYYY, hh:mm A")}
                        .
                      </Typography>
                    )}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <Textarea
                  value={item.description}
                  onChange={e => {
                    setJobPhaseListLoading(false);
                    setphaseItemList(
                      produce(draft => {
                        draft[index] = {
                          ...draft[index],
                          description: e.target.value,
                        };
                      })
                    );
                  }}
                  className="w-full"
                  label="Description"
                />

                <Textarea
                  value={item.revision}
                  onChange={e => {
                    setJobPhaseListLoading(false);
                    setphaseItemList(
                      produce(draft => {
                        draft[index] = {
                          ...draft[index],
                          revision: e.target.value,
                        };
                      })
                    );
                  }}
                  label="Revision"
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="flex justify-end w-full mt-6 mb-2 space-x-2">
        <Button
          label={jobPhaseListLoading ? "Saved" : "Save Changes"}
          onClick={() => updateRevisionPhase()}
        />
        <Button
          label="Add Milestone"
          onClick={() => {
            setphaseItemList(() => {
              return [
                ...phaseItemList,
                {
                  name: "",
                  approvalTimelineType: "text_field",
                  deliveryTimelineType: "text_field",
                  approvalTimeline: "",
                  deliveryTimeline: "",
                  description: "",
                  revision: "",
                  approvalTimezone: Intl.DateTimeFormat().resolvedOptions()
                    .timeZone,
                  deliveryTimezone: Intl.DateTimeFormat().resolvedOptions()
                    .timeZone,
                },
              ];
            });
          }}
        />
      </div>

      <Alert
        isOpen={deleteModalState}
        title="Delete Milestone Item"
        message="Are you sure you want to delete this milestone item?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyPhaseEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
        isSubmitting={btnLoader}
      />
    </>
  );
};

export default PhaseItem;
