import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Pane, Typography, Input, Button, Label } from "@bigbinary/neetoui";
import { useUserState } from "contexts/user";
import {
  DEFAULT_EDITOR_TOOLBAR,
  DEFAULT_EDITOR_FONT_SIZES,
  DEFAULT_EDITOR_PLUGINS,
  DEFAULT_EDITOR_HEIGHT,
} from "common/constants";

const FormPane = ({
  isOpen,
  onClose,
  title,
  formik,
  onSubmit,
  buttonLoader,
}) => {
  const { env_variables } = useUserState();
  const inputRef = useRef();

  const handleTemrsAndConditionChange = content => {
    formik.setFieldValue("description", content);
  };

  return (
    <Pane
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={inputRef}
      size="large"
    >
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          {title}
        </Typography>
      </Pane.Header>
      <Pane.Body>
        <div className="flex flex-col w-full space-y-6">
          <Input
            label="Section"
            ref={inputRef}
            required={true}
            onChange={formik.handleChange}
            value={formik.values.section}
            error={
              Boolean(formik.touched.section && formik.errors.section) &&
              formik.errors.section
            }
            {...formik.getFieldProps("section")}
          />

          <div className="flex flex-col space-y-2">
            <Label>T&C</Label>

            <Editor
              apiKey={env_variables.tinymce_api_key}
              init={{
                height: DEFAULT_EDITOR_HEIGHT,
                plugins: DEFAULT_EDITOR_PLUGINS,
                toolbar: DEFAULT_EDITOR_TOOLBAR,
                font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
              }}
              value={formik.values.description}
              onEditorChange={handleTemrsAndConditionChange}
            />
          </div>
        </div>
      </Pane.Body>
      <Pane.Footer className="flex space-x-2">
        <Button
          label="Save changes"
          onClick={() => onSubmit()}
          loading={buttonLoader}
        />
        <Button label="Cancel" style="text" onClick={() => onClose()} />
      </Pane.Footer>
    </Pane>
  );
};

export default FormPane;
