const routes = {
  settings: {
    businessDetails: "/settings/business_details/:subTab?",
    defaultList: "/settings/default_list/:subTab?",
    milestones: "/settings/milestones",
    usageItems: "/settings/usage_items/:subTab?",
    usageTemplates: "/settings/usage_templates",
    targetAmounts: "/settings/target_amounts",
    estimateLineItems: "/settings/estimate_line_items/:subTab?",
    scopeDefaults: "/settings/scope_defaults/:subTab?",
    crm: "/settings/crm/:subTab?",
    templateSettings: "/settings/template_settings/:subTab?",
    others: "/settings/others/:subTab?",
    show: "/settings/:tab",
    companyDetails: "/settings/:tab/company_details",
    emailDetails: "/settings/:tab/email_details",
    accounts: "/settings/:tab/accounts",
    xero_sessions: "/settings/:tab/xero_sessions",
    defaultKillFees: "/settings/:tab/default_kill_fees",
    defaultLocations: "/settings/:tab/default_locations",
    defaultTaxTypes: "/settings/:tab/default_tax_types",
    categories: "/settings/:tab/categories",
    details: "/settings/:tab/details",
    quantities: "/settings/:tab/quantities",
    territories: "/settings/:tab/territories",
    periods: "/settings/:tab/periods",
    exclusivityPeriods: "/settings/:tab/exclusivity_periods",
    lineItemCategories: "/settings/:tab/line_item_categories",
    estimateLineItemCategories: "/settings/:tab/estimate_line_items",
    metrics: "/settings/:tab/metrics",
    specialJobConditions: "/settings/:tab/special_job_conditions",
    projectScopes: "/settings/:tab/project_scopes",
    deliveryScopes: "/settings/:tab/delivery_scopes",
    jobTemplates: "/settings/:tab/job_templates",
    chatTemplates: "/settings/:tab/chat_templates",
    termsAndConditions: "/settings/:tab/terms_and_conditions",
    crmActionTags: "/settings/:tab/crm_action_tags",
    filterSpecifications: "/settings/:tab/filter_specifications",
    organisationTypes: "/settings/:tab/organisation_types",
    currencies: "/settings/:tab/currencies",
    jobTypes: "/settings/:tab/job_types",
    agencyGroups: "/settings/:tab/agency_groups",
  },
};

export default routes;
