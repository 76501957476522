import React, { useCallback, useRef, useState, useEffect } from "react";
import { ImageUpload } from "@bigbinary/neeto-icons";
import { Button, Modal } from "@bigbinary/neetoui";
import { useDropzone } from "react-dropzone";

const SingleImageUploader = ({
  formValue,
  setFormValue,
  type,
  accept = "image/png, image/gif, image/jpeg, image/jpg",
  setDetailInitial = () => {},
}) => {
  const imageRef = useRef(null);
  const [image, setImg] = useState();
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    if (!initial && image) {
      if (type === "thumbnail") {
        setFormValue("attachments_attributes", {
          ...formValue.attachments_attributes,
          ...image,
        });
      } else {
        let attachments =
          formValue.attachments_attributes?.filter(item => item) || [];

        if (image?.id && image?._destroy === 1) {
          attachments = attachments.filter(att => att.id !== image.id);
        } else if (!image?.id && image?._destroy === 1) {
          attachments = attachments.filter(
            att => att.id || type !== image.attachable_kind
          );
        }

        setFormValue("attachments_attributes", [...attachments, image]);
      }
    }
  }, [image]);

  useEffect(() => {
    if (initial) {
      if (type === "thumbnail") {
        setImg(formValue.attachments_attributes);
      } else {
        setImg(
          formValue.attachments_attributes?.find(
            attachment => attachment?.attachable_kind === type
          )
        );
      }
    }
  }, [formValue]);

  const handleClick = () => {
    imageRef.current.click();
  };

  const handleRemoveClick = () => {
    setInitial(false);
    setDetailInitial(false);
    setImg({ ...image, filename: null, file_data: null, _destroy: 1 });
  };

  const onDrop = useCallback(files => {
    setInitial(false);
    setDetailInitial(false);
    files.map(file => {
      const reader = new FileReader();
      reader.onload = () => {
        setImg({
          filename: file.name,
          attachable_kind: type,
          file_data: reader.result,
          fileType: file.type,
          _destroy: 0,
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: accept,
  });

  const DragDropBaseClass =
    "mb-6 flex justify-center p-2 border neeto-ui-border-primary-500 border-dashed rounded-md transition duration-500 ease-in-out hover:bg-gray-50 cursor-pointer";

  return (
    <div className={DragDropBaseClass}>
      {image?._destroy === 1 || image === undefined ? (
        <div
          className="flex flex-col items-center p-4 space-y-3"
          {...getRootProps()}
        >
          <ImageUpload size={32} className="neeto-ui-text-primary-500" />
          <input
            type="file"
            accept={accept}
            id="photo"
            className="hidden"
            ref={imageRef}
            {...getInputProps()}
          />
          {isDragActive ? (
            <p className={"flex justify-center"}>Drop the file here</p>
          ) : (
            <div className="flex flex-col items-center space-y-1">
              <div className="flex items-center justify-center w-full text-sm neeto-ui-text-gray-800">
                <p>Drag and drop or&nbsp;</p>
                <label
                  htmlFor="file-upload"
                  className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                >
                  <Button
                    style="link"
                    label="Browse"
                    className="underline"
                    onClick={handleClick}
                  />
                </label>
                <p>&nbsp;a file</p>
                <label
                  htmlFor="file-upload"
                  className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                ></label>
              </div>
              <p className="neeto-ui-typography neeto-ui-text-body3 neeto-ui-text-gray-500">
                Only&nbsp;
                <code className="p-0 m-0 bg-white border-l-0 neeto-ui-typography neeto-ui-text-body3">
                  JPEG, JPG, PNG, GIF
                </code>
                &nbsp;files are supported
              </p>
            </div>
          )}
        </div>
      ) : (
        <div
          className="relative flex justify-center m-5 overflow-hidden"
          style={{ maxHeight: "500px" }}
        >
          {image.fileType === "application/pdf" ? (
            <p>{image?.filename}</p>
          ) : (
            <img
              src={image?.file_data || image?.src}
              className={`block object-contain ${
                type === "thumbnail" ? "w-auto" : "w-44"
              }`}
              onClick={() => setShowAttachmentModal(true)}
            />
          )}
          <div
            className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-white bg-red-500 rounded-full cursor-pointer"
            onClick={() => handleRemoveClick()}
          >
            <i className="ri-subtract-line" />
          </div>
        </div>
      )}

      <Modal
        isOpen={showAttachmentModal}
        onClose={() => setShowAttachmentModal(false)}
        size="fullScreen"
      >
        <Modal.Body className="flex justify-center h-screen p-0">
          <img
            src={image?.src || image?.file_data}
            className="object-contain h-screen w-screen"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SingleImageUploader;
