import React, { useEffect, useState, useRef } from "react";
import {
  Copy,
  Delete,
  Filter,
  MenuHorizontal,
  Refresh,
} from "@bigbinary/neeto-icons";
import {
  Button,
  Table,
  Typography,
  Tag,
  Dropdown,
  Alert,
  Pane,
  Spinner,
  Callout,
} from "@bigbinary/neetoui";
import { Header, SubHeader, Container } from "@bigbinary/neetoui/layouts";
import * as R from "ramda";
import * as dayjs from "dayjs";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import {
  humanize,
  numberWithCommas,
  dropDownListGenerator,
  getJobsStatusColor,
  DEFAULT_PAGE_SIZE,
  getRandomNotFoundImage,
  artistModuleName,
} from "common/helper";
import { getJobTypes } from "apis/settings/job_types";
import { getJobs, bulkDelete, bulkClone, destroyJob } from "apis/jobs/jobs";
import { useUserState } from "contexts/user";
import EditJob from "./JobDetailsEdit";
import JobFilter from "./JobFilter";
import EmptyState from "components/Common/EmptyState";
import LocationDropdown from "./../LocationDropdown";
import NewJob from "./NewJob";

const JobListing = () => {
  const { user } = useUserState();
  const initialFocusRef = useRef(null);
  const [jobList, setJobList] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [jobTypeOptionLoad, setJobTypeOptionLoad] = useState(true);
  const [jobListLoad, setJobListLoad] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sortProps, setSortProps] = useState();
  const [jobId, setJobId] = useState("");
  const [jobName, setJobName] = useState("");
  const [jobDetailId, setJobDetailId] = useState("");
  const [editPane, setEditPane] = useState(false);
  const [newJobPane, setNewJobPane] = useState(false);
  const [filterPane, setFilterPane] = useState(false);
  const [bulkDeleteAlert, setBulkDeleteAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [statusFilter, setStatusFilter] = useState();
  const [deletingJobId, setDeletingJobId] = useState("");
  const [jobTypeId, setJobTypeId] = useState();
  const [locationFilter, setLocationFilter] = useState("");
  const [xeroTokenExpired, setXeroTokenExpired] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (user) {
      setLocationFilter(user.location_id);
    }
  }, [user]);

  useEffect(() => {
    if (sortProps?.column && !initialLoad) {
      loadJobListResponse(false);
    }
  }, [sortProps]);

  useEffect(() => {
    if (pageIndex && !initialLoad) {
      loadJobListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadJobListResponse();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (statusFilter) {
      setFilterPane(false);
      loadJobListResponse();
    }
  }, [statusFilter]);

  useEffect(() => {
    if (jobTypeId) {
      setFilterPane(false);
      loadJobListResponse();
    }
  }, [jobTypeId]);

  useEffect(() => {
    if (locationFilter?.length > 0) {
      loadJobListResponse();
    }
  }, [locationFilter]);

  const loadJobTypeList = async () => {
    try {
      const response = await getJobTypes();
      setJobTypeOptions(dropDownListGenerator(response.data.jobTypes));
      setJobTypeOptionLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  // TODO: Fix job filter reset

  const loadJobListResponse = async (load = true) => {
    try {
      if (load) setJobListLoad(true);
      const response = await getJobs(
        searchParams,
        sortProps,
        pageIndex,
        pageSize,
        statusFilter?.value ? statusFilter.value : "",
        locationFilter === "All" ? "" : locationFilter,
        jobTypeId
      );

      setJobList(response.data.jobs);
      setTotalRecords(response.data.totalRecords);
      setXeroTokenExpired(response.data.xeroTokenExpired);
      setJobListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const jobBulkDelete = async () => {
    try {
      await bulkDelete({ jobIds: selectedJobIds });
      loadJobListResponse(true);
      setSelectedJobIds([]);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const jobBulkClone = async () => {
    try {
      await bulkClone({ jobIds: selectedJobIds });
      loadJobListResponse(true);
      setSelectedJobIds([]);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const actionBlockComponent = () => {
    return (
      <div className="flex space-x-3">
        <LocationDropdown
          location={locationFilter}
          setLocation={setLocationFilter}
        />
        <Button
          label="Add Job"
          onClick={() => {
            setNewJobPane(true);
          }}
        />
      </div>
    );
  };

  const onDelete = async () => {
    try {
      await destroyJob(deletingJobId);
      loadJobListResponse();
      setDeleteAlert(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onFilterReset = async () => {
    await setStatusFilter();
    await setJobTypeId();
    await setFilterPane(false);
    await loadJobListResponse();
  };

  const COLUMN_DATA = [
    {
      title: "Job No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 120,
      fixed: "left",
      sorter: true,
      field: "serial",
      render: serialNumber => serialNumber,
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      width: 240,
      sorter: true,
      field: "name",
      fixed: "left",
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/jobs/${rowData.id}/overview`}
            label={rowData.name}
          />
        );
      },
    },
    {
      title: "Organisation",
      dataIndex: "organisationName",
      key: "organisationName",
      field: "organisation_name",
      width: 240,
      sorter: true,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/organisations/${rowData.organisationId}`}
            label={rowData.organisationName}
          />
        );
      },
    },
    {
      title: "Staff",
      dataIndex: "associateStaffs",
      key: "associateStaffs",
      field: "user_full_name",
      width: 192,
      render: (_, rowData) => {
        return (
          <Dropdown
            autoWidth
            closeOnSelect
            trigger="hover"
            position="top"
            customTarget={
              <p className="w-48 truncate">
                {rowData.associateStaffs.map((staff, index) => {
                  return (
                    <>
                      {staff.fullName}
                      {index !== rowData.associateStaffs.length - 1 ? ", " : ""}
                    </>
                  );
                })}
              </p>
            }
          >
            <Menu>
              {rowData.associateStaffs.map(staff => {
                return (
                  <MenuItem.Button
                    className="no-underline"
                    key={staff.id}
                    to={`/staff/${staff.id}`}
                  >
                    {staff.fullName}
                  </MenuItem.Button>
                );
              })}
            </Menu>
          </Dropdown>
        );
      },
    },
    {
      title: artistModuleName(),
      dataIndex: "artists",
      key: "artists",
      field: "artists_full_name",
      width: 192,
      render: (_, rowData) => {
        return (
          <Dropdown
            autoWidth
            closeOnSelect
            trigger="hover"
            position="top"
            customTarget={
              <p className="w-48 truncate">
                {rowData.artists.map((artist, index) => {
                  return (
                    <>
                      {artist.name}
                      {index !== rowData.artists.length - 1 ? ", " : ""}
                    </>
                  );
                })}
              </p>
            }
          >
            <Menu>
              {rowData.artists.map(artist => {
                return (
                  <MenuItem.Button
                    className="no-underline"
                    key={artist.id}
                    to={`/${artistModuleName().toLowerCase()}/${artist.id}`}
                  >
                    {artist.name}
                  </MenuItem.Button>
                );
              })}
            </Menu>
          </Dropdown>
        );
      },
    },
    {
      title: "Enquired On",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      sorter: true,
      field: "enquiry_date",
      width: 130,
      render: enquiryDate => dayjs(enquiryDate).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      field: "status",
      key: "status",
      width: 130,
      render: status => {
        return (
          <Tag
            type="solid"
            style={getJobsStatusColor(status)}
            label={humanize(status)}
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 200,
      render: (_, rowData) => (
        <>
          {rowData.amount
            ? `${numberWithCommas(Number(rowData.amount).toFixed(2))} ${
                rowData.currency
              }`
            : "-"}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 75,
      fixed: "right",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          appendTo={() => document.body}
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setEditPane(true);
                setJobId(rowData.id);
                setJobName(rowData.name);
              }}
            >
              Info
            </MenuItem.Button>
            <MenuItem.Button
              className="no-underline"
              onClick={() => {
                setJobDetailId(rowData.id);
                setNewJobPane(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setDeletingJobId(rowData.id);
                setDeleteAlert(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  const renderInfoToast = () => {
    return <Callout className="w-full mb-3">Xero Logged in!</Callout>;
  };

  if (jobListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Jobs"
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(""),
        }}
        actionBlock={actionBlockComponent()}
      />
      <SubHeader
        leftActionBlock={
          <Typography style="h4" weight="semibold" component="h4">
            {totalRecords > 0 && totalRecords} Jobs
          </Typography>
        }
        rightActionBlock={
          <div className="flex space-x-3">
            {!R.isEmpty(selectedJobIds) && (
              <div className="flex space-x-3">
                <Button
                  icon={Copy}
                  style="secondary"
                  label="Duplicate"
                  iconPosition="left"
                  onClick={() => jobBulkClone()}
                />
                <Button
                  icon={Delete}
                  style="danger"
                  label="Delete"
                  iconPosition="left"
                  onClick={() => setBulkDeleteAlert(true)}
                />
              </div>
            )}
            <Button
              icon={Filter}
              style="secondary"
              label="Filter"
              iconPosition="left"
              onClick={async () => {
                await loadJobTypeList();
                setFilterPane(true);
              }}
            />
          </div>
        }
      />

      {!R.isNil(xeroTokenExpired) && !xeroTokenExpired && renderInfoToast()}
      <div
        className="w-full"
        style={{
          height:
            !R.isNil(xeroTokenExpired) && !xeroTokenExpired
              ? "calc(100vh - 257px)"
              : "calc(100vh - 205px)",
        }}
      >
        {!R.isEmpty(jobList) ? (
          <Table
            loading={jobListLoad}
            fixedHeight
            rowSelection
            columnData={COLUMN_DATA}
            rowData={jobList}
            totalCount={totalRecords}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            selectedRowKeys={selectedJobIds}
            onRowSelect={selectedRowKeys => {
              setSelectedJobIds(selectedRowKeys);
            }}
            handlePageChange={(page, pageSize) => {
              setInitialLoad(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) => {
              setInitialLoad(false);
              setSortProps(sorter);
            }}
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Jobs Found"
            description="We couldn’t find any jobs. Try creating one."
            primaryButtonProps={{
              label: "Add Job",
              onClick: () => setNewJobPane(true),
            }}
          />
        )}
      </div>

      <Pane isOpen={editPane} onClose={() => setEditPane(false)}>
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {jobName}
          </Typography>
        </Pane.Header>
        <EditJob
          jobId={jobId}
          setEditPane={setEditPane}
          setJobId={setJobId}
          loadJobListResponse={loadJobListResponse}
        />
      </Pane>

      <Pane isOpen={filterPane} onClose={() => setFilterPane(false)}>
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            Filter
          </Typography>
        </Pane.Header>
        <Pane.Body>
          <JobFilter
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            jobTypeId={jobTypeId}
            setJobTypeId={setJobTypeId}
            jobTypeOptions={jobTypeOptions}
            jobTypeOptionLoad={jobTypeOptionLoad}
          />
        </Pane.Body>
        <Pane.Footer className="gap-x-2">
          <Button
            label="Reset"
            style="secondary"
            icon={Refresh}
            iconPosition="left"
            onClick={() => onFilterReset()}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setFilterPane(false)}
          />
        </Pane.Footer>
      </Pane>

      <NewJob
        isOpen={newJobPane}
        jobId={jobDetailId}
        onClose={() => {
          setJobDetailId("");
          setNewJobPane(false);
        }}
        setNewJobPane={setNewJobPane}
        initialFocusRef={initialFocusRef}
      />

      <Alert
        isOpen={bulkDeleteAlert}
        title="Delete Job"
        message="Are you sure you want to delete the job/jobs?"
        onClose={() => setBulkDeleteAlert(false)}
        onSubmit={() => {
          jobBulkDelete();
          setBulkDeleteAlert(false);
        }}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />

      <Alert
        isOpen={deleteAlert}
        title="Delete Job"
        message="You are going to delete a job. Once completed, this action cannot be undone."
        onClose={() => setDeleteAlert(false)}
        onSubmit={() => onDelete()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default JobListing;
