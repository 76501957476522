import React, { useState, useEffect } from "react";
import { Plus, CloseCircle } from "@bigbinary/neeto-icons";
import {
  Input,
  Textarea,
  Label,
  Spinner,
  Button,
  Select,
  Typography,
  Switch,
} from "@bigbinary/neetoui";
import CountryPhone from "components/Common/CountryPhone";
import CountrySelect from "components/Common/CountrySelect";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import { countries } from "countries-list";
import {
  getOrganisationTypes,
  createOrganisationType,
} from "apis/settings/organisation_types";
import { RegionDropdown } from "react-country-region-selector";

const EditOrganisationDetails = ({ inputRef, formik }) => {
  const [primaryCountry, setPrimaryCountry] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();
  const [secondaryCountry, setSecondaryCountry] = useState("");
  const [organisationTypeList, setOrganisationTypeList] = useState([]);
  const [organisationTypeLoad, setOrganisationTypeLoad] = useState(true);
  const [sameAsPrimary, setSameAsPrimary] = useState(false);
  const [hideClass, setHideClass] = useState("");

  useEffect(() => {
    loadTypeList();
    formik.setFieldValue("secondaryAddressDestroy", true);
    if (!formik.values.id) {
      setHideClass("hidden");
      formik.setFieldValue("removeSecondaryAddress", true);
    }
  }, []);

  useEffect(() => {
    if (formik.values.id && !formik.values.secondaryAddressId) {
      setHideClass("hidden");
    }
  }, [formik.values.secondaryAddressId]);

  useEffect(() => {
    if (primaryCountry?.value) {
      formik.setFieldValue("primaryCountry", primaryCountry?.value);
    }
  }, [primaryCountry]);

  useEffect(() => {
    if (secondaryCountry?.value) {
      formik.setFieldValue("secondaryCountry", secondaryCountry?.value);
    }
  }, [secondaryCountry]);

  useEffect(() => {
    setSecondaryCountry(formik.values.secondaryCountry);
  }, [formik.values.secondaryCountry]);

  useEffect(() => {
    if (formik.values.primaryCountry) {
      let country = formik.values.primaryCountry;

      let countryObj = Object.entries(countries).find(item => {
        return (
          item[0].toLowerCase() === country?.toLowerCase() ||
          item[1].name.toLowerCase() === country?.toLowerCase()
        );
      });

      if (countryObj) {
        setPrimaryCountry(countryObj[0]);
        setSelectedCountry(countryObj[1]);
      }
    }
  }, [formik.values.primaryCountry]);

  useEffect(() => {
    if (formik.values.secondaryCountry) {
      let country = formik.values.secondaryCountry;
      let countryObj = Object.entries(countries).find(item => {
        return (
          item[0].toLowerCase() === country?.toLowerCase() ||
          item[1].name.toLowerCase() === country?.toLowerCase()
        );
      });

      countryObj && setSecondaryCountry(countryObj[0]);
    }
  }, [formik.values.secondaryCountry]);

  const loadTypeList = async () => {
    try {
      const response = await getOrganisationTypes();
      setOrganisationTypeList(
        dropDownListGenerator(response.data.organisationTypes)
      );
      setOrganisationTypeLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleOrgTypeCreate = async value => {
    try {
      setOrganisationTypeLoad(true);
      const response = await createOrganisationType({
        organisation_type: { name: value },
      });
      formik.setFieldValue("organisationTypeId", response.data.value);
      await loadTypeList();
      setOrganisationTypeLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (organisationTypeLoad) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full pb-6 space-y-6">
      <Input
        required
        ref={inputRef}
        label="Organisation ID"
        name="organisationSerial"
        onChange={formik.handleChange}
        value={formik.values.organisationSerial}
        error={
          Boolean(
            formik.touched.organisationSerial &&
              formik.errors.organisationSerial
          ) && formik.errors.organisationSerial
        }
        {...formik.getFieldProps("organisationSerial")}
      />

      <Input
        name="name"
        label="Organisation Name"
        onChange={formik.handleChange}
        value={formik.values.name}
        required={true}
        error={
          Boolean(formik.touched.name && formik.errors.name) &&
          formik.errors.name
        }
        {...formik.getFieldProps("name")}
      />

      <Select
        label="Type"
        isCreateable
        name="organisationTypeId"
        id="organisationTypeId"
        placeholder="Select a Type"
        isLoading={organisationTypeLoad}
        onCreateOption={handleOrgTypeCreate}
        options={organisationTypeList}
        value={organisationTypeList.find(
          obj => obj.value === formik.values.organisationTypeId
        )}
        error={
          Boolean(
            formik.touched.organisationTypeId &&
              formik.errors.organisationTypeId
          ) && formik.errors.organisationTypeId
        }
        onChange={opt => {
          formik.setFieldTouched("organisationTypeId", true);
          formik.setFieldValue("organisationTypeId", opt.value);
        }}
      />

      <Typography style="h4" weight="semibold">
        Primary Address
      </Typography>
      <Textarea
        label="Street Address"
        name="streetAddress"
        value={formik.values.primaryStreetAddress}
        error={
          Boolean(
            formik.touched.primaryStreetAddress &&
              formik.errors.primaryStreetAddress
          ) && formik.errors.primaryStreetAddress
        }
        rows="3"
        {...formik.getFieldProps("primaryStreetAddress")}
      />

      <div className="grid w-full grid-cols-2 gap-4">
        <Input
          label="City/Suburb"
          name="suburb"
          onChange={formik.handleChange}
          value={formik.values.primarySuburb}
          error={
            Boolean(
              formik.touched.primarySuburb && formik.errors.primarySuburb
            ) && formik.errors.primarySuburb
          }
          {...formik.getFieldProps("primarySuburb")}
        />

        <CountrySelect
          label="Country"
          onCountryChange={setPrimaryCountry}
          country={primaryCountry}
          showCountryName={true}
          error={
            Boolean(
              formik.touched.primaryCountry && formik.errors.primaryCountry
            ) && formik.errors.primaryCountry
          }
        />
      </div>

      <div className="grid w-full grid-cols-2 gap-4">
        <Input
          label="Postcode"
          name="postcode"
          onChange={formik.handleChange}
          value={formik.values.primaryPostcode}
          error={
            Boolean(
              formik.touched.primaryPostcode && formik.errors.primaryPostcode
            ) && formik.errors.primaryPostcode
          }
          {...formik.getFieldProps("primaryPostcode")}
        />
        <div className="flex flex-col neeto-ui-input__wrapper">
          <Label className="mb-2">State</Label>
          <RegionDropdown
            blankOptionLabel="No country selected."
            defaultOptionLabel="Select State"
            country={formik.values.primaryCountry}
            value={formik.values.primaryState}
            onChange={val => {
              formik.setFieldValue("primaryState", val);
            }}
            classes="neeto-ui-input neeto-ui-input--medium"
            style={{
              padding: "5px 8px",
            }}
          />
        </div>
      </div>

      <CountryPhone
        formik={formik}
        required={false}
        selectedCountry={selectedCountry}
      />

      {hideClass === "hidden" ? (
        <div className="flex justify-end">
          <Button
            style="link"
            icon={Plus}
            iconPosition="left"
            label="Add Secondary Address"
            onClick={() => {
              formik.setFieldValue("secondaryAddressDestroy", false);
              setHideClass("");
            }}
          />
        </div>
      ) : (
        ""
      )}

      <div className={`${hideClass}`}>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <Typography style="h4" weight="semibold">
              Secondary Address
            </Typography>
            <Button
              style="text"
              icon={CloseCircle}
              tooltipProps={{
                content: "Remove Secondary Address",
                position: "top",
              }}
              onClick={async () => {
                setHideClass("hidden");
                formik.setFieldValue("secondaryStreetAddress", "");
                formik.setFieldValue("secondaryState", null);
                formik.setFieldValue("secondarySuburb", null);
                formik.setFieldValue("secondaryCountry", null);
                formik.setFieldValue("secondaryPostcode", null);
                setSecondaryCountry(null);
                formik.setFieldValue("secondaryAddressDestroy", true);
              }}
            />
          </div>
          <Switch
            label="Same as Primary"
            checked={sameAsPrimary}
            onClick={() => {
              setSameAsPrimary(true);
              formik.setFieldValue("secondaryAddressDestroy", false);
              formik.setFieldValue(
                "secondaryStreetAddress",
                formik.values.primaryStreetAddress
              );
              formik.setFieldValue(
                "secondaryState",
                formik.values.primaryState
              );
              formik.setFieldValue(
                "secondarySuburb",
                formik.values.primarySuburb
              );
              formik.setFieldValue(
                "secondaryCountry",
                formik.values.primaryCountry
              );
              formik.setFieldValue(
                "secondaryPostcode",
                formik.values.primaryPostcode
              );
            }}
          />
        </div>

        <Textarea
          label="Street Address"
          name="streetAddress"
          value={formik.values.secondaryStreetAddress}
          error={
            Boolean(
              formik.touched.secondaryStreetAddress &&
                formik.errors.secondaryStreetAddress
            ) && formik.errors.secondaryStreetAddress
          }
          className="mb-3"
          rows="3"
          {...formik.getFieldProps("secondaryStreetAddress")}
        />

        <div className="grid w-full grid-cols-2 gap-4">
          <Input
            label="City/Suburb"
            name="suburb"
            onChange={formik.handleChange}
            value={formik.values.secondarySuburb}
            error={
              Boolean(
                formik.touched.secondarySuburb && formik.errors.secondarySuburb
              ) && formik.errors.secondarySuburb
            }
            className="mt-3 mb-3 mr-4"
            {...formik.getFieldProps("secondarySuburb")}
          />

          <CountrySelect
            label="Country"
            onCountryChange={setSecondaryCountry}
            country={secondaryCountry}
            showCountryName={true}
            error={
              Boolean(
                formik.touched.secondaryCountry &&
                  formik.errors.secondaryCountry
              ) && formik.errors.secondaryCountry
            }
          />
        </div>

        <div className="grid w-full grid-cols-2 gap-4">
          <Input
            label="Postcode"
            name="postcode"
            onChange={formik.handleChange}
            value={formik.values.secondaryPostcode}
            error={
              Boolean(
                formik.touched.secondaryPostcode &&
                  formik.errors.secondaryPostcode
              ) && formik.errors.secondaryPostcode
            }
            {...formik.getFieldProps("secondaryPostcode")}
          />

          <div className="flex flex-col neeto-ui-input__wrapper">
            <Label className="mb-2">State</Label>
            <RegionDropdown
              blankOptionLabel="No country selected."
              defaultOptionLabel="Select State"
              country={formik.values.secondaryCountry}
              value={formik.values.secondaryState}
              onChange={val => {
                formik.setFieldValue("secondaryState", val);
              }}
              classes="neeto-ui-input neeto-ui-input--medium"
              style={{
                padding: "5px 8px",
              }}
            />
          </div>
        </div>
      </div>

      <Input
        label="Website"
        name="website"
        onChange={formik.handleChange}
        value={formik.values.website}
        prefix="https://"
        error={
          Boolean(formik.touched.website && formik.errors.website) &&
          formik.errors.website
        }
        {...formik.getFieldProps("website")}
      />

      <Input
        label="Vendor ID"
        name="vendorId"
        onChange={formik.handleChange}
        value={formik.values.vendorId}
        error={
          Boolean(formik.touched.vendorId && formik.errors.vendorId) &&
          formik.errors.vendorId
        }
        {...formik.getFieldProps("vendorId")}
      />

      <Input
        type="number"
        label="Invoice Payment Terms"
        name="invoicePaymentTerms"
        onChange={formik.handleChange}
        value={formik.values.invoicePaymentTerms}
        error={
          Boolean(
            formik.touched.invoicePaymentTerms &&
              formik.errors.invoicePaymentTerms
          ) && formik.errors.invoicePaymentTerms
        }
        {...formik.getFieldProps("invoicePaymentTerms")}
      />

      <Textarea
        label="Payment Term Details"
        name="paymentTerms"
        onChange={formik.handleChange}
        value={formik.values.paymentTerms}
        error={
          Boolean(formik.touched.paymentTerms && formik.errors.paymentTerms) &&
          formik.errors.paymentTerms
        }
        {...formik.getFieldProps("paymentTerms")}
      />

      <Textarea
        label="Notes"
        name="notes"
        onChange={formik.handleChange}
        value={formik.values.notes}
        error={
          Boolean(formik.touched.notes && formik.errors.notes) &&
          formik.errors.notes
        }
        {...formik.getFieldProps("notes")}
      />
    </div>
  );
};

export default EditOrganisationDetails;
